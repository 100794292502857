import axios from 'axios'
import { addDocuments, getDocuments, getStationPath } from 'common/apis/maindata'
import { DocumentManager } from 'components/DocumentManager'
import React, { useState, useEffect, useMemo } from 'react'

export default function DeviceDoc({ device }) {
  const [basePath, setBasePath] = useState(null)
  const initDirectories = () => {
    const tree = {
      id: null,
      key: basePath,
      name: null,
      fullName: basePath,
      customerId: device.customerId,
      fileType: 1,
      extraProperties: {},
      title: device.name,
      children: [
        {
          id: null,
          key: `${basePath}/检验报告`,
          name: '检验报告',
          title: '检验报告',
          fullName: `${basePath}/检验报告`,
          customerId: device.customerId,
          fileType: 1,
          extraProperties: {},
          children: [
            {
              id: null,
              key: `${basePath}/检验报告/出厂报告`,
              name: '出厂报告',
              title: '出厂报告',
              fullName: `${basePath}/检验报告/出厂报告`,
              customerId: device.customerId,
              fileType: 1,
              extraProperties: {},
              children: [],
            },
          ],
        },
        {
          id: null,
          key: `${basePath}/说明书`,
          name: '说明书',
          title: '说明书',
          fullName: `${basePath}/说明书`,
          customerId: device.customerId,
          fileType: 1,
          extraProperties: {},
          children: [],
        },
        {
          id: null,
          key: `${basePath}/图纸`,
          name: '图纸',
          title: '图纸',
          fullName: `${basePath}/图纸`,
          customerId: device.customerId,
          fileType: 1,
          extraProperties: {},
          children: [],
        },
      ],
    }
    return tree
  }
  const directoryTree=useMemo(initDirectories,basePath)

  const onUploaded = (files) => {
    query()
  }
  function query() {
    axios.get(getStationPath(device.id)).then((response) => {
      const data = response.data
      setBasePath(data)
    })
  }
  useEffect(() => {
    query()
  }, [device])
  useEffect(() => {
    if (basePath) {
      initDirectories()
    }
  }, [basePath])
  return (
    <div style={{ height: '100%' }}>
      <div className='widget'>
        <div className='widget-header'>
          <div className='widget-title'>站点相关文档</div>
        </div>
        <div className='widget-body device-profile-details'>
          <DocumentManager
            basePath={basePath}
            onUploaded={onUploaded}
            initDirectory={directoryTree}
            onCreatedDirectory={({ action, item, data }) => {
              console.log(data)
              onUploaded([data])
            }}
            onDeleted={() => {
              query()
            }}
          ></DocumentManager>
        </div>
      </div>
    </div>
  )
}
