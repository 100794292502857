import React, { useState, useRef } from 'react'
import { WithTooltip, Tooltip } from '@abb/abb-common-ux-react'
import { getUserProfile } from 'slices/userProfileSlice'
import { useSelector } from 'react-redux'
import { CommonIndex } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import moment from 'moment'

import { useDispatch } from 'react-redux'
import { deleteExpert, getExpertPage } from 'common/apis/remoteSupport'
import { ImportFormEdit } from './ImportFormEdit'

export default function Experts() {
  const [deletedItems, setDeletedItems] = useState([])
  const refImport = useRef(null)

  const refIndex = useRef(null)


  //列定义
  const columns = [
    {
      title: '姓名',
      key: 'name',
      //通过渲染的方式将内容渲染出来
      render: (item, index) => {
        const isDeleted = deletedItems.indexOf(item.id) >= 0
        if (isDeleted)
          return (
            <WithTooltip>
              <del>{item.name}</del>
              <Tooltip>
                <div>工程师已被删除</div>
              </Tooltip>
            </WithTooltip>
          )
        else return item.name
      },
    },
    {
      title: 'ID',
      key: 'engineerId',
    },
    {
      title: '有效期限',
      key: 'beginDate',
      render: (item) => {
        return `${item.beginDate?moment(item.beginDate).format('yyyy-MM-DD'):''}~${item.endDate?moment(item.endDate).format('yyyy-MM-DD'):''}`
      },
    },
    {
      title: '头衔',
      key: 'title',
    },
    {
      title: '是否专家',
      key: 'isExpert',
      render: (item) => {
        return item.isExpert ? '是' : '否'
      },
    },
    {
      title: '是否推荐专家',
      key: 'isRecommend',
      render: (item) => {
        return item.isRecommend ? '是' : '否'
      },
    },
  ]

  //删除时放入删除项
  const onDeleteData = (deleteId) => {
    setDeletedItems([...deletedItems, deleteId])
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getExpertPage}
        deleteData={deleteExpert}
        FormEdit={FormEdit}
        canAdd
        canEdit
        canDelete
        onDeleteData={onDeleteData}
        onImport={() => refImport.current.showPanel()}
        canInport
        enableSorting={true}
      />
      <ImportFormEdit
              ref={refImport}
              onSubmit={() => {
                refIndex.current.query()
              }}
            />
    </>
  )
}
