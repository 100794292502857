import React, { useState, useRef } from 'react'
import { getUserProfile } from 'slices/userProfileSlice'
import { useSelector } from 'react-redux'
import axios from 'axios'
// import { CommonConfirm } from "components/LoongInCommon"
import { CommonConfirm, CommonIndex, CommonTable } from 'components/LoongInCommon'
import moment from 'moment'
import { getEnv } from 'slices/envSlice'

import { useDispatch } from 'react-redux'
// import { ResponseResult } from "common/request"
import { t } from 'i18next'
import { generateTotalReport, getTotalReportPage } from 'common/apis/totalreports'
import { nanoid } from 'nanoid'
import { CommonPrompt, CommonPromptButtons } from 'components/LoongInCommon/CommonPrompt'
import { approveSingleReport, generateSingleReport, submitSingleReport } from 'common/apis/singlereports'
import { getCurrentUser, getPermissions } from 'slices/abpApplicationConfigurationSlice'
import { getUsers } from 'slices/userSlice'
import { downloadFile } from 'common/apis/maindata'
import { ShowTotalReport } from './dialogs/ShowTotalReport'
import { EditTotalReport } from './dialogs/EditTotalReport'
import { ApproveTotalReport } from './dialogs/ApproveTotalReport'
import { ImportTestResultFormEdit } from './forms/ImportTestResultFormEdit'
import { BackTotalReport } from './dialogs/BackTotalReport'
import { ShowSingleReport } from './dialogs/ShowSingleReport'

export default function Reports() {
  const env = useSelector(getEnv)
  const userProfile = useSelector(getCurrentUser)
  const [totalReportGenerate, setTotalReportGenerate] = useState(null)
  const [allSingleReportGenerate, setAllSingleReportGenerate] = useState(null)
  const [singleReportApprovl, setSingleReportApprovl] = useState(null)
  const [singleReportGenerate, setSingleReportGenerate] = useState(null)
  const [singleReportSubmit, setSingleReportSubmit] = useState(null)
  const [singleReportBack, setSingleReportBack] = useState(null)
  const [isSingleReportLoading, setIsSingleReportLoading] = useState(false)
  const [isSingleGenerating, setIsSingleGenerating] = useState(false)
  const [isAllSingleGenerating, setIsAllSingleGenerating] = useState(false)
  const [isSingleSubmiting, setIsSingleSubmiting] = useState(false)
  const [isSingleBacking, setIsSingleBacking] = useState(false)
  const [isTotalGenerating, setIsTotalGenerating] = useState(false)
  const permissions = useSelector(getPermissions)
  const dispatch = useDispatch()
  const refIndex = useRef(null)
  const singlePreviewRef = useRef(null)
  const totalPreviewRef = useRef(null)
  const totalEditwRef = useRef(null)
  const totalApproveRef = useRef(null)
  const totalBackRef = useRef(null)
  const formDeviceTypeRef = useRef(null)
  const users = useSelector(getUsers)

  const totalStatus = [
    { key: -2, title: '已退回' },
    { key: -1, title: '已驳回' },
    { key: 0, title: '待编辑' },
    { key: 1, title: '待审核' },
    { key: 2, title: '待生成' },
    { key: 3, title: '生成中' },
    { key: 4, title: '已生成' },
    { key: 5, title: '等待生成' },
  ]
  const singleStatus = [
    { key: -2, title: '已退回' },
    { key: -1, title: '已驳回' },
    { key: 0, title: '待编辑' },
    { key: 1, title: '待审核' },
    { key: 2, title: '待生成' },
    { key: 3, title: '生成中' },
    { key: 4, title: '已生成' },
    { key: 5, title: '等待生成' },
  ]
  //列定义
  const childrenColumns = [
    {
      title: '设备',
      key: 'device',
    },
    {
      title: '单体报告名称',
      key: 'name',
    },
    {
      title: '状态',
      key: 'status',
      render: (item) => {
        return singleStatus.find((s) => s.key === item.status)?.title
      },
    },
    {
      title: '创建时间',
      key: 'creationTime',
      render: (item) => {
        return moment(item.creationTime).format('yyyy-MM-DD HH:mm:ss')
      },
    },
    {
      title: '审批时间',
      key: 'approvalDate',
      render: (item) => {
        return item.approvalDate && moment(item.approvalDate, false).format('yyyy-MM-DD HH:mm:ss')
      },
    },
    {
      title: '审批人',
      key: 'approvalUser',
      render: (item, index) => {
        const user = users.find((d) => d.id == item.approvalUser)
        return user?.name ?? '未审批'
      },
    },
  ]

  const hasPermission = (permission) => {
    return !permissions || !permission || (permission in permissions && permissions[permission])
  }
  //其他行菜单
  const singleMenu = (total, item, index) => {
    const items = []
    if (total.project.projectType !== '6') {
      items.push({
        onClick: () => {
          singlePreviewRef.current.showDialog(item)
        },
        title: t('t_detail'),
      })
      if (item.status <= 0 && total.project.managerId === userProfile?.id) {
        items.push(
          {
            onClick: () => {
              formDeviceTypeRef.current.showPanel(item.id)
            },
            title: '导入机械特性试验数据',
          },
          {
            onClick: () => {
              setSingleReportSubmit(item)
            },
            title: '提交审批',
          }
        )
      }
      if (item.status >= 0 && total.project.managerId === userProfile?.id) {
        items.push({
          onClick: () => {
            setSingleReportBack(item)
          },
          title: '退回报告',
        })
      }
      if (item.status === 1 && item.inScope && hasPermission('EzReport.SingleReport.Approve')) {
        items.push({
          onClick: () => {
            setSingleReportApprovl(item)
          },
          title: '审批',
        })
      }
      if (item.status === 2 && total.project.managerId === userProfile?.id && hasPermission('EzReport.SingleReport.Generate')) {
        items.push({
          onClick: () => {
            setSingleReportGenerate(item)
          },
          title: '生成报告',
        })
      }
    }
    if (item.status === 4 && item.file) {
      if (hasPermission('EzReport.SingleReport.Generate')) {
        items.push({
          onClick: () => {
            setSingleReportGenerate(item)
          },
          title: '重新生成报告',
        })
      }
      items.push({
        onClick: () => {
          window.open(`${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${downloadFile()}?id=${item.file}`, '_blank')
        },
        title: '下载报告',
      })
    }
    return items
  }

  //列定义
  const columns = [
    {
      title: '项目名称',
      key: 'project',
      render: (item, index) => {
        return item.project.name
      },
      expand: (item, index) => {
        const list = item.singleReports
        if (list.length == 0) return <div style={{ marginLeft: '20px' }}>没有数据</div>
        return (
          <div style={{ padding: '10px 20px 10px 20px', overflow: 'visible' }}>
            <CommonTable
              otherMenu={(r, index) => singleMenu(item, r, index)}
              zebra={true}
              columns={childrenColumns}
              data={list}
              showPage={false}
              keyField='key'
            />
          </div>
        )
      },
      //通过渲染的方式将内容渲染出来
    },
    {
      title: '变电站名称',
      key: 'station',
      render: (item, index) => {
        return item.station.name
      },
    },
    {
      title: '总体维保报告名称',
      key: 'name',
    },
    {
      title: '状态',
      key: 'status',
      render: (item) => {
        return totalStatus.find((s) => s.key === item.status)?.title
      },
    },
    {
      title: '创建时间',
      key: 'creationTime',
      render: (item) => {
        return moment(item.creationTime).format('yyyy-MM-DD HH:mm:ss')
      },
    },
    {
      title: '审批时间',
      key: 'approvalDate',
      render: (item) => {
        return item.approvalDate && moment(item.approvalDate).format('yyyy-MM-DD HH:mm:ss')
      },
    },
    {
      title: '审批人',
      key: 'approvalUser',
      render: (item, index) => {
        const user = users.find((d) => d.id === item.approvalUser)
        return user?.name ?? '未审批'
      },
    },
  ]

  //其他行菜单
  const totalMenu = (item, index) => {
    if (item.singleReports.some((s) => s.status < 2)) {
      return []
    }
    const items = []
    if (item.project.projectType !== '6') {
      items.push({
        onClick: () => {
          totalPreviewRef.current.showDialog(item)
        },
        title: t('t_detail'),
      })
      if (item.status <= 0 && item.project.managerId === userProfile?.id && hasPermission('EzReport.TotalReport.Update')) {
        items.push({
          onClick: () => {
            totalEditwRef.current.showDialog(item)
          },
          title: '编辑',
        })
      }
      if (item.status > 0 && item.project.managerId === userProfile?.id && hasPermission('EzReport.TotalReport.Update')) {
        items.push({
          onClick: () => {
            totalBackRef.current.showDialog(item)
          },
          title: '退回',
        })
      }
      if (item.status === 1 && item.inScope && hasPermission('EzReport.TotalReport.Approve')) {
        items.push({
          onClick: () => {
            totalApproveRef.current.showDialog(item)
          },
          title: '审批',
        })
      }
      if (item.status === 2 && item.project.managerId === userProfile?.id && hasPermission('EzReport.TotalReport.Generate')) {
        items.push({
          onClick: () => {
            setTotalReportGenerate(item)
          },
          title: '生成报告',
        })
      }
    }
    if (item.project.managerId === userProfile.id && hasPermission('EzReport.TotalReport.Generate')) {
      items.push({
        onClick: () => {
          setAllSingleReportGenerate(item)
        },
        title: '重新生成全部单体报告',
      })
    }
    if (item.status === 4 && item.file) {
      if (item.project.managerId === userProfile?.id && hasPermission('EzReport.TotalReport.Generate')) {
        items.push({
          onClick: () => {
            setTotalReportGenerate(item)
          },
          title: '重新生成报告',
        })
        items.push({
          onClick: () => {
            setAllSingleReportGenerate(item)
          },
          title: '重新生成全部单体报告',
        })
      }
      
      items.push({
        onClick: () => {
          window.open(`${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${downloadFile()}?id=${item.file}`)
        },
        title: '下载报告',
      })
    }
    return items
  }
  const onSingleReportApprovl = (value, result) => {
    if (result === CommonPromptButtons.Yes || result === CommonPromptButtons.No) {
      setIsSingleReportLoading(true)
      axios.post(approveSingleReport(singleReportApprovl.id), { status: result === CommonPromptButtons.Yes ? 2 : -1, suggestion: value }).then(() => {
        setIsSingleReportLoading(false)
        setSingleReportApprovl(null)
        setTimeout(() => refIndex.current.query(), 500)
      })
    } else {
      setSingleReportApprovl(null)
    }
  }

  const onSingleReportSubmit = () => {
    setIsSingleSubmiting(true)
    axios.post(submitSingleReport(singleReportSubmit.id), { status: 1, suggestion: '' }).then(() => {
      setIsSingleSubmiting(false)
      setSingleReportSubmit(null)
      setTimeout(() => refIndex.current.query(), 500)
    })
  }

  const onSingleReportBack = () => {
    setIsSingleBacking(true)
    axios.post(submitSingleReport(singleReportBack.id), { status: -2, suggestion: '' }).then(() => {
      setIsSingleBacking(false)
      setSingleReportBack(null)
      setTimeout(() => refIndex.current.query(), 500)
    })
  }

  const onSingleReportGenerate = () => {
    setIsSingleGenerating(true)
    axios.post(generateSingleReport(singleReportGenerate.id), {}).then(() => {
      setIsSingleGenerating(false)
      setSingleReportGenerate(null)
      setTimeout(() => refIndex.current.query(), 500)
    })
  }

  const onAllSingleReportGenerate = () => {
    setIsAllSingleGenerating(true)
    Promise.all(allSingleReportGenerate.singleReports.filter(s=>s.status===4).map((s => axios.post(generateSingleReport(s.id), {})))).then(() => {
      setIsAllSingleGenerating(false)
      setAllSingleReportGenerate(null)
      setTimeout(() => refIndex.current.query(), 500)
    })
  }
  const onTotalReportGenerate = () => {
    setIsTotalGenerating(true)
    axios.post(generateTotalReport(totalReportGenerate.id), {}).then(() => {
      setIsTotalGenerating(false)
      setTotalReportGenerate(null)
      setTimeout(() => refIndex.current.query(), 500)
    })
  }

  const getGetListInput = (params) => {
    return { ...params, type: '2,6,7' }
  }

  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        getGetListInput={getGetListInput}
        otherMenu={totalMenu}
        columns={columns}
        getList={getTotalReportPage}
        enableSorting={true}
      />
      <ShowSingleReport key={nanoid()} ref={singlePreviewRef} role='action' />
      <ShowTotalReport key={nanoid()} ref={totalPreviewRef} role='action' />
      <EditTotalReport key={nanoid()} ref={totalEditwRef} role='action' onSubmit={() => setTimeout(() => refIndex.current.query(), 500)} />
      <ApproveTotalReport key={nanoid()} ref={totalApproveRef} role='action' onSubmit={() => setTimeout(() => refIndex.current.query(), 500)} />
      <BackTotalReport key={nanoid()} ref={totalBackRef} role='action' onSubmit={() => setTimeout(() => refIndex.current.query(), 500)} />
      <CommonPrompt
        title={'审核单体报告'}
        content={'请输入您的审核意见'}
        isVisible={singleReportApprovl != null}
        yesText={'批准'}
        noText={'驳回'}
        buttons={CommonPromptButtons.Yes | CommonPromptButtons.No | CommonPromptButtons.Cancel}
        onClose={onSingleReportApprovl}
        isLoading={isSingleReportLoading}
      />
      <CommonConfirm
        title={'生成报告'}
        content={'是否确定要生成单体报告？'}
        isVisible={singleReportGenerate != null}
        onConfirm={onSingleReportGenerate}
        confirmText={t('b_confirm')}
        onCancel={() => setSingleReportGenerate(null)}
        isLoading={isSingleGenerating}
      />
      <CommonConfirm
        title={'提交报告'}
        content={'是否确定要提交单体报告？'}
        isVisible={singleReportSubmit != null}
        onConfirm={onSingleReportSubmit}
        confirmText={t('b_confirm')}
        onCancel={() => setIsSingleSubmiting(null)}
        isLoading={isSingleSubmiting}
      />
      <CommonConfirm
        title={'退回报告'}
        content={'是否确定要退回单体报告？'}
        isVisible={singleReportBack != null}
        onConfirm={onSingleReportBack}
        confirmText={t('b_confirm')}
        onCancel={() => setIsSingleBacking(null)}
        isLoading={isSingleBacking}
      />
      <CommonConfirm
        title={'生成报告'}
        content={'是否确定要生成总体报告？'}
        isVisible={totalReportGenerate != null}
        onConfirm={onTotalReportGenerate}
        confirmText={t('b_confirm')}
        onCancel={() => setTotalReportGenerate(null)}
        isLoading={isTotalGenerating}
      />
      <CommonConfirm
        title={'生成报告'}
        content={'是否确定要生成全部单体报告？'}
        isVisible={allSingleReportGenerate != null}
        onConfirm={onAllSingleReportGenerate}
        confirmText={t('b_confirm')}
        onCancel={() => setAllSingleReportGenerate(null)}
        isLoading={isAllSingleGenerating}
      />
      <ImportTestResultFormEdit ref={formDeviceTypeRef} />
    </>
  )
}
