import React, { useState, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import { LoadingIndicator } from "@abb/abb-common-ux-react"

import { useSelector, useDispatch } from "react-redux"
import { t } from "i18next"
import { ErrorBoundary } from "common/ErrorBoundary"
import ProjectInfo from "./ProjectInfo"
import { MemberList } from "./MemberList"
import { SearchUser } from "./SearchUser"
import "./dashboard.scss"
import { fetchProjectAsync, getProject, getIsLoadingProject, getCustomer, getIsLoadingCustomer, fetchCustomerAsync } from "slices/ezProjectSlice"
import { fetchGetUsersAsync, getUsers } from "slices/userSlice"
import CustomerInfo from "./CustomerInfo"
import { getOrganization, fetchGetOrganizationAsync } from "slices/organizationSlice"
import { ContactList } from "./ContactList"
import { RangeList } from "./RangeList"
import { SearchItem } from "./SearchItem"
import { Preview } from "./Preview"
import { usePermission } from "hooks/usePermission"
import { SearchTemplate } from './SearchTemplate'

export default function Project({ id }) {
  const urlParams = useParams()
  const dispatch = useDispatch()
  const project = useSelector(getProject)
  const customer = useSelector(getCustomer)
  const users = useSelector(getUsers)
  const organization = useSelector(getOrganization)
  const memberlistRef = useRef(null)
  const rangelistRef = useRef(null)
  const searchUserRef = useRef(null)
  const searchItemRef = useRef(null)
  const previewRef = useRef(null)
  const isLoadingProject = useSelector(getIsLoadingProject)
  const isLoadingCustomer = useSelector(getIsLoadingCustomer)
  const canUpdate = usePermission('MainData.Project.Update')
  const projectInfoRef = useRef(null)
  const searchTempRef = useRef(null)

  useEffect(() => {
    if (urlParams.id) {
      dispatch(fetchProjectAsync(urlParams.id))
    }
  }, [urlParams.id])
  useEffect(() => {
    if (id) {
      dispatch(fetchProjectAsync(id))
    }
  }, [id])
  useEffect(() => {
    if (project?.customerId && !customer) {
      dispatch(fetchCustomerAsync(project.customerId))
    }
  }, [project])
  useEffect(() => {
    if (!organization) {
      dispatch(fetchGetOrganizationAsync())
    }
  }, [organization])
  useEffect(() => {
    if (!users) {
      dispatch(fetchGetUsersAsync())
    }
  }, [users])
  const onSubmit = (d) => {}
  if (isLoadingProject || isLoadingCustomer) {
    return (
      <div className="central-position">
        <LoadingIndicator type="radial" sizeClass="medium" determinate={false} color="blue" />
      </div>
    )
  } else if (project) {
    return (
      <ErrorBoundary>
        <div className="grid root-grid" data-cy="module-device-dashboard">
          <div className="row abb-col-lg-14 abb-col-md-24 abb-col-ms-24">
            <div className="grid" style={{ margin: 0 }}>
              <div className="row  abb-col-lg-12 abb-col-md-24 abb-col-ms-24">
                <div className="tile height-6x abb-col-lg12-24 abb-col-md-24 abb-col-ms-24 over-hidden-ms">
                  <CustomerInfo />
                </div>
              </div>
              <div className='row  abb-col-lg-12 abb-col-md-24 abb-col-ms-24'>
                <div className='tile height-6x abb-col-lg12-24 abb-col-md-24 abb-col-ms-24 over-hidden-ms'>
                  <ProjectInfo ref={projectInfoRef}
                    onEdit={() => {
                      searchTempRef.current.showDialog(project.id)
                    }}/>
                </div>
              </div>

              <div className="row  abb-col-lg-24 abb-col-md-24 abb-col-ms-24">
                <div className="tile height-4x abb-col-lg12-24 abb-col-md-24 abb-col-ms-24 over-hidden-ms">
                  <RangeList readOnly={!canUpdate}
                    onEdit={(item) => {
                      searchItemRef.current.showDialog(project.id, item.id, item.deviceTypes, item.items)
                    }}
                    onPreview={(item) => {
                      previewRef.current.showDialog(project, item.id)
                    }}
                    ref={rangelistRef}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row  abb-col-lg-10 abb-col-md-24 abb-col-ms-24">
            <div className="grid" style={{ margin: 0 }}>
              <div className="row  abb-col-lg-24 abb-col-md-24 abb-col-ms-24">
                <div className="tile height-5x  abb-col-lg8-24 abb-col-md8-24 abb-col-ms8-24">
                  <MemberList readOnly={!canUpdate}
                    ref={memberlistRef}
                    onEdit={(role, multipleChoice, removeOld, old) => {
                      searchUserRef.current.showDialog(project.id, role, multipleChoice, removeOld, old)
                    }}
                  />
                </div>
              </div>
              <div className="row  abb-col-lg-24 abb-col-md-24 abb-col-ms-24">
                <div className="tile height-5x  abb-col-lg8-24 abb-col-md8-24 abb-col-ms8-24">
                  <ContactList readOnly={!canUpdate} onEdit={() => {}} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <DataFormEdit ref={formDataRef} formData={DataFormData} id={device.id} customerId={customerId} onSubmit={onSubmit} /> */}
        <SearchUser ref={searchUserRef} role="action" onRefresh={() => setTimeout(() => memberlistRef.current.query(), 500)} />
        <SearchItem ref={searchItemRef} role="action" onRefresh={() => setTimeout(() => rangelistRef.current.query(), 500)} />
        <SearchTemplate ref={searchTempRef} role='action' onRefresh={() => setTimeout(() =>      dispatch(fetchProjectAsync(urlParams.id)), 500)} />

        <Preview ref={previewRef} role="action" />
      </ErrorBoundary>
    )
  }
  return (
    <div className="grid root-grid">
      <p>您没有访问此页面的权限</p>
    </div>
  )
  //返回页面
}
