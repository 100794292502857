import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getDevice, getParent, getDeviceType, getStation } from 'slices/deviceSlice'
import { getFactories } from 'slices/factorySlice'
import { Table, Tooltip, WithTooltip, Button } from '@abb/abb-common-ux-react'
import { nanoid } from 'nanoid'
import { t } from 'i18next'
import moment from 'moment'
import { getCustomer } from 'slices/ezProjectSlice'
import { useDataDictionary } from 'hooks/useDataDictionary'
import { getUsers } from 'slices/userSlice'
import { getOrganization } from 'slices/organizationSlice'
import { findTree } from 'common/utils'
import { SlidingCard } from 'components/SlidingCard'
import { ManagerFormEdit } from './ManagerFormEdit'
import { getEnv } from 'slices/envSlice'
import axios from 'axios'
import { generateCustomerManagerInfo, getCustomerManagerInfo, viewFile } from 'common/apis/maindata'

export default function CustomerInfo({ onEdit }) {
  const device = useSelector(getCustomer)
  const parent = useSelector(getParent)
  const custCate = useDataDictionary('custCate')
  const srvArea = useDataDictionary('srvArea')
  const users = useSelector(getUsers)
  const organization = useSelector(getOrganization)
  const formDataRef = useRef(null)
  const station = useSelector(getStation)
  const type = useSelector(getDeviceType)
  const [pageIndex, setPageIndex] = useState(0)
  const factories = useSelector(getFactories)
  const env = useSelector(getEnv)

  console.log('parent', parent)
  let profiles = []
  const factory = factories?.find((f) => f.id == device?.factoryId)
  profiles = [
    [
      { title: t('customer.name'), value: device?.name },
      { title: t('customer.code'), value: device?.code },
      { title: t('customer.category'), value: custCate?.details.find((d) => d.code == device.category)?.displayText },
      { title: t('customer.manager'), value: users.find((d) => d.id == device.manager)?.name },
      { title: t('customer.serviceUnitId'), value: findTree(organization, (d) => d.key == device.serviceUnitId)?.title },
      { title: t('customer.contact'), value: device.contact },
      { title: t('customer.phone'), value: device?.phone },
      { title: t('customer.email'), value: device?.email },
      { title: t('customer.area'), value: srvArea?.details.find((d) => d.code == device.area)?.displayText },
      { title: t('customer.address'), value: device?.address },
    ],
  ]

  useEffect(() => {
    window.addEventListener('resize', onWindowResize)
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [])
  const onWindowResize = () => {
    setPageIndex(0)
  }
  return (
    <>
      <SlidingCard>
        {profiles.map((list) =>
          list.length === 0 ? null : (
            <Table
              sizeClass='medium'
              borderType='discreet'
              key={nanoid()}
              otherButton={[
                <Button
                  key={nanoid()}
                  sizeClass='small'
                  shape='pill'
                  type='discreet-black'
                  icon='abb/certificate-document-2'
                  disabled={!device?.extraProperties?.ManagerInfo?.CertificateFile}
                  onClick={() =>
                    window.open(
                      `${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${viewFile()}?id=${
                        device?.extraProperties?.ManagerInfo?.CertificateFile
                      }`
                    )
                  }
                  style={{ opacity: 1, marginRight: 8 }}
                />,
                <Button
                  key={nanoid()}
                  sizeClass='small'
                  shape='pill'
                  type='discreet-black'
                  icon='abb/certificate-document-1'
                  style={{ opacity: 1, marginRight: 8 }}
                  disabled={!device?.extraProperties?.ManagerInfo}
                  onClick={() => {
                    axios.get(generateCustomerManagerInfo(), { params: { customerId: device.id } }).then((r) => {
                      onEdit ?? onEdit()
                    })
                  }}
                />,
                <Button
                  key={nanoid()}
                  sizeClass='small'
                  shape='pill'
                  type='discreet-black'
                  onClick={() => formDataRef.current.setShowSidePanel(true)}
                  icon='abb/user'
                  style={{ opacity: 1, marginRight: 8 }}
                />,
              ]}
            >
              <tbody>
                {list.map((item) => (
                  <tr key={nanoid()}>
                    <td title={item.title}>{item.title}</td>
                    <td>
                      {item.value && (
                        <WithTooltip>
                          <span>{item.value}</span>
                          <Tooltip>
                            <div>
                              {item.title}：{item.value}
                            </div>
                          </Tooltip>
                        </WithTooltip>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )
        )}
      </SlidingCard>
      <ManagerFormEdit ref={formDataRef} id={device?.id} onSubmit={onEdit} />
    </>
  )
}
