import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { CommonFormEdit } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { getCustomerManagerInfo, setCustomerManagerInfo } from 'common/apis/maindata'
import { getClientHeight } from 'slices/appSlice'
import moment from 'moment'

export const ManagerFormEdit = forwardRef(({ id, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [rules, setRules] = useState({})
  const [formItem, setFormItem] = useState([])
  const [innerData, setInnerData] = useState({})
  const [formData, setFormData] = useState({})

  useEffect(() => {
    setInnerData(formData)
  }, [formData])
  //初始化数据
  const initData = {
    id: '',
    rightId: '',
    arguments: '',
    beginDate: null,
    endDate: null,
  }

  //表单数据项
  const changeFormItem = () => {
    const items = [
      {
        type: 'date',
        label: '开始日期',
        fieldName: 'beginDate',
        dateFormat: 'yyyy-MM-dd',
        maxDate: innerData ? innerData.endDate : undefined,
        showClearIcon: true,
      },
      {
        type: 'date',
        label: '结束日期',
        fieldName: 'endDate',
        dateFormat: 'yyyy-MM-dd',
        minDate: innerData ? innerData.beginDate : undefined,
        showClearIcon: true,
      },
      {
        type: 'text',
        dataType: 'textarea',
        label: '服务范围',
        fieldName: 'serviceScope',
        maxLength: 256,
      },
      {
        type: 'text',
        dataType: 'textarea',
        label: '描述',
        fieldName: 'description',
        maxLength: 256,
      },
    ]
    setFormItem(items)
  }

  useEffect(() => {
    if (innerData) {
      changeFormItem()
    }
  }, [innerData])
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      beginDate: [CommonRule.Require],
      endDate: [CommonRule.Require],
      serviceScope: [CommonRule.Require],
      description: [CommonRule.Require],
    }
    setRules(newrules)
  }, [id])
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      beginDate: inputdata.beginDate,
      endDate: inputdata.endDate,
      serviceScope: inputdata.serviceScope,
      description: inputdata.description,
    }
  }


  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    axios.get(getCustomerManagerInfo(), { params: { customerId: id } }).then((r) => {
      setFormData(r.data)
    })
    formEditRef.current.setShowSidePanel(visible)
  }

  const onChange = (data, preData) => {
    if (
      (data.beginDate && !data.endDate) ||
      (preData.beginDate && moment(preData.beginDate).add(1, 'y').format('yyyy-MM-DD') === preData.endDate && data.endDate === preData.endDate)
    ) {
      data.endDate = moment(data.beginDate).add(1, 'y').format('yyyy-MM-DD')
    }
    console.log(data)
    setInnerData(data)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title='客户经理信息'
      formItem={formItem}
      initData={initData}
      rules={rules}
      updateData={setCustomerManagerInfo}
      getUpdateInput={getUpdateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      onChange={onChange}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
