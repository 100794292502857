import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  memo,
} from "react"
import {
  Checkbox,
  Dialog,
  LoadingIndicator,
  NumericInput,
} from "@abb/abb-common-ux-react"
import { getReportDetail, getTotalReportDetail } from "common/apis/totalreports"
import "../../../../styles/totalReport.scss"

import axios from "axios"
import moment from "moment"
import { Image } from "components/Image"
import envs from "./enviroment.json"
import { useEffect } from "react"
import * as echarts from "echarts"
import { groupBy, sumBy, uniq, uniqBy } from "lodash"
import { tr } from "date-fns/locale"
import { nanoid } from "nanoid"
import { ScatterChart } from "./ScatterChart"
import { ImageSelector } from "components/ImageSelector"
import { Logger } from "msal"
import { cloneUniformsGroups } from "three/src/renderers/shaders/UniformsUtils"
import { MarkdownEditor } from "components/MarkdownEditor"
const totalStatus = [
  { key: -2, title: "已退回" },
  { key: -1, title: "已驳回" },
  { key: 0, title: "待编辑" },
  { key: 1, title: "待审核" },
  { key: 2, title: "待生成" },
  { key: 3, title: "生成中" },
  { key: 4, title: "已生成" },
  { key: 5, title: "等待生成" },
]
const totalStatusText = [
  { key: -2, title: "退回意见" },
  { key: -1, title: "驳回意见" },
]

const DevicesDangerTB = memo(
  forwardRef(({ data, getText, readonly, projectId }, ref) => {
    const [devicesDanger, setDevicesDanger] = useState({})
    useEffect(() => {
      if (data?.length) {
        const newDevicesDanger = data?.reduce((pre, cur) => {
          pre[cur.id] = {}
          let option = cur.options.find((o) => o.id == cur.result.option)
          pre[cur.id].description = cur.result?.description || getText(cur)
          pre[cur.id].optionId = cur.result.option
          pre[cur.id].checkPointId = cur.checkPointId
          pre[cur.id].deviceId = cur.deviceId
          pre[cur.id].projectId = projectId
          // console.log(cur, "newDevicesDanger")
          return pre
        }, {})
        setDevicesDanger(newDevicesDanger)
      }
    }, [data, projectId])
    const getDevCheckPoint = () => {
      return Object.values(devicesDanger)
    }
    useImperativeHandle(ref, () => ({
      getDevCheckPoint,
    }))
    return (
      <>
        <h4
          style={{
            marginTop: 0,
            borderTop: "2px double black",
            borderBottom: "2px double black",
            backgroundColor: "#f2f2f2",
            padding: 8,
          }}
        >
          主要风险隐患
        </h4>
        <table
          style={{
            width: "100%",
            margin: 0,
            tableLayout: "fixed",
            borderBottom: "2px double black",
          }}
        >
          <tr style={{ fontWeight: "normal" }}>
            <td
              key={nanoid()}
              style={{ width: "5%", borderRight: "0.5px solid black" }}
            >
              序号
            </td>
            <td
              style={{
                textAlign: "center",
                padding: 4,
                width: "46%",
                borderRight: "0.5px solid black",
              }}
            >
              图片
            </td>
            {/* <td
                style={{
                  textAlign: "center",
                  padding: 4,
                  width: "26%",
                  borderRight: "0.5px solid black",
                }}
              >
                风险说明
              </td> */}
            <td
              style={{
                textAlign: "center",
                padding: 4,
                width: "46%",
                borderRight: "0.5px solid black",
              }}
            >
              备注
            </td>
          </tr>
          {data?.map((d, i) => {
            return (
              // <tr key={nanoid()}>
              <tr key={d.id}>
                <td
                  style={{
                    textAlign: "center",
                    padding: 4,
                    borderTop: "0.5px solid black",
                    borderRight: "0.5px solid black",
                  }}
                >
                  {i + 1}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    padding: 4,
                    borderTop: "0.5px solid black",
                    borderRight: "0.5px solid black",
                    display: "flex",
                  }}
                >
                  <div style={{ flex: 1, verticalAlign: "middle" }}>
                    {getText(d)}
                  </div>
                  <div style={{ flex: 1 }}>
                    <Image
                      id={d.result.beforPhoto}
                      style={{ maxWidth: 230, maxHeight: 230 }}
                    />
                  </div>
                </td>
                {/* <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    {getText(d)}
                  </td> */}
                <td
                  style={{
                    textAlign: "center",
                    padding: 4,
                    borderTop: "0.5px solid black",
                    position: "relative",
                  }}
                >
                  {/* {getText(d)} */}
                  <div
                    style={{
                      position: "absolute",
                      top: 4,
                      right: 4,
                      left: 4,
                      bottom: 4,
                    }}
                  >
                    <textarea
                      style={{ height: "100%", resize: "none" }}
                      id={d.id}
                      // value={devicesDanger?.[d.id]}
                      defaultValue={devicesDanger?.[d.id]?.description || ""}
                      readOnly={readonly}
                      onBlur={(e) => {
                        setDevicesDanger((n) => {
                          console.log(n, "setDevicesDanger")
                          n[[d.id]].description = e.target.value
                          return {
                            ...n,
                          }
                        })
                      }}
                      // onChange={(e) =>
                      //   setDevicesDanger((n) => {
                      //     return {
                      //       ...n,
                      //       [d.id]: e.target.value,
                      //     }
                      //   })
                      // }
                      name={d.id}
                      className="k-textbox"
                      rows="4"
                    ></textarea>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      </>
    )
  })
)

const DevicesRiskIndex = memo(
  forwardRef(({ detail, readonly, formatter }, ref) => {
    const [devicesOffset, setDevicesOffset] = useState({})
    const getAfterOffset = (modal, offset) => {
      if (!modal) return 0
      return formatter.format(modal + modal * ((offset || 0) / 100))
    }
    const getAfterOffsetV2 = (modal, offset) => {
      if (!modal) return 0
      return modal + modal * ((offset || 0) / 100)
    }
    useEffect(() => {
      if (detail) {
        const devicesData = detail?.data.devices[0]
        const newDevicesOffset = devicesData?.devices?.reduce((pre, cur) => {
          pre[cur.device?.id] = {
            id: cur.device?.id,
            summaries: {
              Offset: +((cur?.beforeChart?.offset || 0) * 100 || 0).toFixed(1),
              AfterOffset: +((cur?.afterChart?.offset || 0) * 100 || 0).toFixed(
                1
              ),
            },
          }
          console.log(pre[cur.device?.id], "newDevicesOffset")
          // pre[cur.device?.id] = 11;
          return pre
        }, {})
        setDevicesOffset(newDevicesOffset)
      }
    }, [detail])
    const getDevicesOffset = () => {
      return Object.values(devicesOffset)?.map((item) => {
        item.summaries.Offset = `${(item.summaries.Offset || 0) / 100}`
        item.summaries.AfterOffset = `${
          (item.summaries.AfterOffset || 0) / 100
        }`
        return item
      })
    }
    useImperativeHandle(ref, () => ({
      getDevicesOffset,
    }))
    return (
      <>
        <h4
          style={{
            marginTop: 16,
            borderTop: "2px double black",
            borderBottom: "2px double black",
            backgroundColor: "#f2f2f2",
            padding: 8,
          }}
        >
          {detail?.station?.name}设备风险指数
        </h4>
        <table
          style={{
            width: "100%",
            margin: 0,
            tableLayout: "fixed",
          }}
        >
          <tr style={{ fontWeight: "normal" }}>
            <td
              key={nanoid()}
              style={{
                textAlign: "center",
                width: "5%",
                borderRight: "0.5px solid black",
              }}
            >
              序号
            </td>
            <td
              style={{
                textAlign: "center",
                padding: 4,
                width: "15%",
                borderRight: "0.5px solid black",
              }}
            >
              设备名称（线路名称）
            </td>
            <td
              style={{
                textAlign: "center",
                padding: 4,
                width: "9%",
                borderRight: "0.5px solid black",
              }}
            >
              设备型号
            </td>
            <td
              style={{
                textAlign: "center",
                padding: 4,
                width: "13%",
                borderRight: "0.5px solid black",
              }}
            >
              设备重要性指数
            </td>
            <td
              colSpan={2}
              style={{
                textAlign: "center",
                borderRight: "0.5px solid black",
                padding: 4,
                width: "20%",
              }}
            >
              设备风险指数
            </td>

            <td
              style={{
                textAlign: "center",
                padding: 4,
                width: "10%",
                borderRight: "0.5px solid black",
              }}
            >
              整改前
            </td>
            <td
              style={{
                textAlign: "center",
                padding: 4,
                width: "8%",
                borderRight: "0.5px solid black",
              }}
            >
              整改前指数
            </td>

            <td
              style={{
                textAlign: "center",
                padding: 4,
                width: "10%",
                borderRight: "0.5px solid black",
              }}
            >
              整改后
            </td>
            <td
              style={{
                textAlign: "center",
                padding: 4,
                width: "8%",
                borderRight: "0.5px solid black",
              }}
            >
              整改前指数
            </td>
          </tr>
          {detail?.data.devices
            ?.flatMap((d) => d.devices)
            ?.map((d, i) => {
              return (
                <tr key={d?.device?.id || i}>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    {i + 1}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    {d.device?.displayName}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    {d.device.modal}%
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    {formatter.format(d?.beforeChart?.importance)}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      tableLayout: "fixed",
                      width: "20px",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    {formatter.format(d?.beforeChart?.health)}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      width: "30%",
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    {d?.beforeChart?.healthPlace?.name}
                  </td>

                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    <NumericInput
                      min={-100}
                      max={100}
                      disabled={readonly}
                      disablePlus={readonly}
                      disableMinus={readonly}
                      disableEdit={readonly}
                      suffix={<span>%</span>}
                      value={devicesOffset[d?.device?.id]?.summaries?.Offset}
                      onChange={(newValue) => {
                        setDevicesOffset((n) => {
                          console.log(newValue, n, "setDevicesOffset")
                          n[[d?.device?.id]].summaries.Offset = newValue
                          return {
                            ...n,
                          }
                        })
                      }}
                    />
                  </td>

                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    {formatter.format(
                      d?.beforeChart?.health +
                        (devicesOffset[d?.device?.id]?.summaries?.Offset || 0) /
                          100
                    )}
                  </td>

                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    <NumericInput
                      min={-100}
                      max={100}
                      disabled={readonly}
                      disablePlus={readonly}
                      disableMinus={readonly}
                      disableEdit={readonly}
                      suffix={<span>%</span>}
                      value={
                        devicesOffset[d?.device?.id]?.summaries?.AfterOffset
                      }
                      onChange={(newValue) => {
                        setDevicesOffset((n) => {
                          n[[d?.device?.id]].summaries.AfterOffset = newValue
                          return {
                            ...n,
                          }
                        })
                      }}
                    />
                  </td>

                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    {formatter.format(
                      d?.beforeChart?.health +
                        (devicesOffset[d?.device?.id]?.summaries?.Offset || 0) /
                          100 +
                        (devicesOffset[d?.device?.id]?.summaries?.AfterOffset ||
                          0) /
                          100
                    )}
                  </td>
                </tr>
              )
            })}
        </table>
      </>
    )
  })
)
export const TotalReport = forwardRef(({ report, readonly = false }, ref) => {
  const [detail, setDetail] = useState(null)
  const DevicesDangerTBRef = useRef(null)
  const DevicesRiskIndexRef = useRef(null)
  const [summaries, setSummaries] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [devicesOffset, setDevicesOffset] = useState({})
  const [devicesDangerData, setDevicesDangerData] = useState([])
  // const [devicesDanger, setDevicesDanger] = useState({})
  const [stationEnvironmentalDanger, setStationEnvironmentalDanger] = useState(
    []
  )
  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 1,
  })

  let index = 0
  const fetchPreviewInfo = (reportId) => {
    setIsLoading(true)
    axios
      .get(getReportDetail(), { params: { id: reportId } })
      .then(async (response) => {
        const data = response.data
        await setDetail(data)

        const newDevicesDangerData = uniqBy(
          data?.data.devices
            ?.flatMap((d) => d.devices)
            .flatMap((d) => {
              let newddata = d.data?.map((item) => {
                item.deviceId = d.device.id
                return item
              })
              return newddata
            })
            .flatMap((d) => {
              let newcheckItems = d.result.checkItems?.map((item) => {
                item.deviceId = d.deviceId
                item.projectId = data.project.id
                return item
              })
              return newcheckItems
            })
            .flatMap((c) => {
              const newpoints = c.points?.map((item) => {
                item.deviceId = c.deviceId
                item.checkPointId = item.id
                return item
              })
              // console.log(newpoints, "dddddddddd")
              return newpoints
            })
            .filter((o) => o.result.code === "2" && getValue(o) >= 7),
          "id"
        )

        // const newDevicesDanger = newDevicesDangerData?.reduce((pre, cur) => {
        //   pre[cur.id] = {}
        //   let option = cur.options.find((o) => o.id == cur.result.option)
        //   pre[cur.id].description = cur.result?.description || ""
        //   pre[cur.id].optionId = cur.result.option
        //   pre[cur.id].checkPointId = cur.checkPointId
        //   pre[cur.id].deviceId = cur.deviceId
        //   pre[cur.id].projectId = data.project.id
        //   // console.log(cur, "newDevicesDanger")
        //   return pre
        // }, {})
        // const devicesData = data?.data.devices[0]
        // const newDevicesOffset = devicesData?.devices?.reduce((pre, cur) => {
        //   pre[cur.device?.id] = {
        //     id: cur.device?.id,
        //     summaries: {
        //       Offset: cur.afterChart?.offset,
        //       AfterOffset: cur.afterChart?.offset,
        //     },
        //   }

        //   // pre[cur.device?.id] = 11;
        //   return pre
        // }, {})
        const newStationEnvironmentalDanger = data?.station.parameters
          ?.flatMap((d) => d.datas)
          .flatMap((d) => d.parameters)
          .flatMap((d) => Object.values(d))
          // .flatMap((d) => {
          //   const v=Object.values(d)
          //   console.log('v',v);
          //   return v
          // })
          .filter(
            (o) =>
              o.options.find((i) => i.id === o?.result?.option)?.key === "2" &&
              getValue(o) > 6
          )
        console.log(newStationEnvironmentalDanger, "stationEnvironmentalDanger")
        await setStationEnvironmentalDanger(newStationEnvironmentalDanger)
        await setDevicesDangerData(newDevicesDangerData)
        // await setDevicesDanger(newDevicesDanger)
        // await setDevicesOffset(newDevicesOffset)
        await setSummaries({
          SummaryDiscovery: "",
          CabinetWorkOtherAdvice: "",
          DeviceMaintenanceState: "",
          SummaryConclusion: "",
          DeviceStateScan: "",
          DeviceProblemSummaryRemark: "",
          ExpProblemSummaryRemark: "",
          ...(data.report.summaries || {}),
        })

        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (report) {
      fetchPreviewInfo(report.id)
    }
  }, [report])

  const getReport = () => {
    return detail?.report
  }

  useEffect(() => {
    console.log("summary", summaries)
  }, [summaries])

  const getSummaries = () => {
    return summaries
  }
  const getDevicesOffset = () => {
    return DevicesRiskIndexRef?.current?.getDevicesOffset()
  }

  const setSummary = (key, value) => {
    setSummaries((n) => {
      return {
        ...n,
        [key]: value,
      }
    })
  }
  const getDevCheckPoint = () => {
    return DevicesDangerTBRef?.current?.getDevCheckPoint()
  }
  const getStationCheckPoint = (key, value) => {
    return stationEnvironmentalDanger?.reduce((pre, cur) => {
      let arr = {
        projectId: detail?.project?.id,
        stationId: detail?.station?.id,
        optionId: cur?.option?.id,
        description: cur.description,
      }
      pre.push(arr)
      return pre
    }, [])
  }

  const getText = (point) => {
    var option = point.options.find((o) => o.id == point.result.option)
    if (option === null) {
      return ""
    }
    if (
      !option.children ||
      !point.result.additionalValues ||
      point.result.additionalValues === ""
    ) {
      return `${option.title}:${option.valueExpression}`
    }
    var options = point.result.additionalValues
      .split(",")
      .map((t) => t.toLowerCase())
    return (
      uniq(
        option.children
          .filter((o) => options.indexOf(o.id) >= 0)
          .map((o) => `${o.title}:${o.valueExpression}`)
      ).join(",") ?? `${option?.title}:${option?.valueExpression}`
    )
  }
  const getValue = (point) => {
    var option = point.options.find((o) => o.id === point.result.option)
    var v = 1

    if (!option) {
      return 0
    }
    if (!option.children && !!option.valueExpression) {
      v = Number(option.valueExpression)
    }
    if (!!option.children) {
      var options =
        point.result.additionalValues?.split(",").map((t) => t.toLowerCase()) ??
        []
      //   const optionsIds = new Set(options.map(o => o.id));
      var o = option.children.filter((o) => options.indexOf(o.id) >= 0)
      v = o.reduce((l, o) => l + Number(o.valueExpression), 0)
      if (v === 0 && option?.valueExpression !== undefined) {
        v = Number(option.valueExpression)
      }
    }

    if (v === 1) {
      return 0
    }
    if (v === 0) {
      return 3
    }
    if (v >= 10) {
      return 10 * point.failureMultiple
    }
    return v
  }
  useImperativeHandle(ref, () => ({
    getReport,
    getSummaries,
    getDevicesOffset,
    getDevCheckPoint,
    getStationCheckPoint,
  }))
  function getRankColorFromNum(rank) {
    try {
      var color = ""
      switch (rank) {
        case 1:
          color = "red"
          break
        case 2:
          color = "orange"
          break
        case 3:
          color = "yellow"
          break
        default:
          color = "green"
          break
      }
      return color
    } catch (exception) {}
  }
  return (
    <>
      {isLoading && (
        <div style={{ backgroundColor: "#fff", width: "100%", height: "100%" }}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translateX(-50%) translateY(-50%)",
              zIndex: 9999,
            }}
          >
            <LoadingIndicator
              type="radial"
              sizeClass="medium"
              determinate={false}
              color="blue"
            />
          </div>
        </div>
      )}
      <div
        style={{ visibility: isLoading ? "hidden" : "visible" }}
        className="total-report"
      >
        <div className="cover" style={{ textAlign: "left" }}>
          <img
            src="/ABB-logo.svg"
            style={{
              width: 120,
              float: "right",
              clear: "both",
              display: "block",
            }}
          />
          <div
            style={{
              height: 8,
              width: 52,
              background: "red",
              clear: "both",
              marginBottom: 32,
            }}
          ></div>
          <h1 style={{ clear: "both" }}>
            <span data-project="customerCompanyName">
              {detail?.project?.customer?.name}
            </span>
          </h1>
          {/* <h2>
            <span data-switchingroom='name'>{detail?.station?.name}</span>
          </h2> */}
          <h2>
            <span data-device="name">{detail?.deviceTitle}</span>
            开关设备运行风险评估报告
          </h2>
          <div
            style={{ textAlign: "left", flexDirection: "row", display: "flex" }}
          >
            <dl
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <dt>报告状态：</dt>
              <dd>
                {
                  totalStatus.find((s) => s.key === detail?.report?.status)
                    ?.title
                }
              </dd>
              {detail?.report?.status < 0 && (
                <>
                  <dt style={{ marginTop: 16 }}>
                    {
                      totalStatusText.find(
                        (s) => s.key === detail?.report?.status
                      )?.title
                    }
                  </dt>
                  <dd>{detail?.report?.suggestion}</dd>
                </>
              )}
            </dl>
            <div
              style={{
                clear: "both",
                margin: "auto",
                height: 360,
                maxWidth: "60%",
              }}
            >
              {!readonly && (
                <ImageSelector
                  value={summaries?.Cover}
                  path={"/_/Ezreport/Covers/"}
                  defaultImage="/templates/TotalReportCover.png"
                  onValueChanged={(d) => setSummary("Cover", d[0].id)}
                />
              )}
              {readonly && (
                <Image
                  id={summaries?.Cover}
                  defaultImage="/templates/TotalReportCover.png"
                  style={{ maxWidth: "100%", height: 360 }}
                />
              )}
            </div>
          </div>
          <p>厦门ABB开关有限公司</p>
        </div>
        <div className="summary">
          <div
            style={{
              height: 8,
              width: 52,
              background: "red",
              clear: "both",
              marginBottom: 32,
            }}
          ></div>
          <h2 style={{ textAlign: "left" }}>报告摘要</h2>
          <table
            style={{
              width: "100%",
              tableLayout: "fixed",
              border: "2px double black",
              borderCollapse: "collapse",
              borderRight: "none",
              borderLeft: "none",
            }}
          >
            <tr style={{ fontWeight: "normal" }}>
              <td style={{ width: "5%", borderRight: "0.5px solid black" }}>
                序号
              </td>
              <td style={{ width: "30%", borderRight: "0.5px solid black" }}>
                配电室名称
              </td>
              <td style={{ width: "15%", borderRight: "0.5px solid black" }}>
                额定电压
              </td>
              <td style={{ width: "17%", borderRight: "0.5px solid black" }}>
                变电站重要性指数
              </td>
              <td style={{ width: "17%", borderRight: "0.5px solid black" }}>
                设备风险指数
              </td>
              <td style={{ width: "17%" }}>运行环境风险指数</td>
            </tr>
            {detail?.deviceSummaries?.map((d, i) => {
              return (
                <tr key={nanoid()} style={{ borderTop: "0.5px solid black" }}>
                  <td style={{ borderRight: "0.5px solid black" }}>{i + 1}</td>
                  <td style={{ borderRight: "0.5px solid black" }}>{d.name}</td>
                  <td style={{ borderRight: "0.5px solid black" }}>
                    {d.voltage}
                  </td>
                  <td style={{ borderRight: "0.5px solid black" }}>
                    {d.importance.toFixed(2)}%
                  </td>
                  <td style={{ borderRight: "0.5px solid black" }}>
                    {(100 - d.health).toFixed(2)}%
                  </td>
                  <td>{d.environmental.toFixed(2)}%</td>
                </tr>
              )
            })}
          </table>
          <ul>
            <li>
              <h4>用户配电系统单线图:</h4>
              <div
                style={{
                  clear: "both",
                  margin: "auto",
                  height: 360,
                  maxWidth: "60%",
                }}
              >
                {!readonly && (
                  <ImageSelector
                    value={summaries?.SingleLine}
                    path={"/_/Ezreport/SingleLine/"}
                    onValueChanged={(d) => setSummary("SingleLine", d[0].id)}
                  />
                )}
                {readonly && (
                  <Image
                    id={summaries?.SingleLine}
                    style={{ maxWidth: "100%", height: 360 }}
                  />
                )}
              </div>
            </li>
            <li>
              <h4>主要问题:</h4>
              <MarkdownEditor
                options={{
                  cache: {
                    enable: false,
                  },
                }}
                id="SummaryDiscovery1"
                value={summaries?.SummaryDiscovery}
                readOnly={readonly}
                onChange={(e) => {
                  setSummaries((n) => {
                    console.log(
                      {
                        ...n,
                        SummaryDiscovery: e,
                      },
                      "dadddd"
                    )
                    return {
                      ...n,
                      SummaryDiscovery: e,
                    }
                  })
                }}
              />
            </li>
            <li>
              <h4>ABB建议措施:</h4>
              <MarkdownEditor
                options={{
                  cache: {
                    enable: false,
                  },
                }}
                id="CabinetWorkOtherAdvice"
                value={summaries?.CabinetWorkOtherAdvice}
                readOnly={readonly}
                onChange={(e) => {
                  setSummaries((n) => {
                    return {
                      ...n,
                      CabinetWorkOtherAdvice: e,
                    }
                  })
                }}
              />
            </li>
          </ul>
        </div>
        <div className="summary">
          <div
            style={{
              height: 8,
              width: 52,
              background: "red",
              clear: "both",
              marginBottom: 32,
            }}
          ></div>
          <h2 style={{ textAlign: "left" }}>项目信息</h2>
          <div style={{ marginTop: 16 }}>
            本项目需要评估的开关设备，设备信息如下。
          </div>
          <table
            style={{
              width: "100%",
              tableLayout: "fixed",
              border: "2px double black",
              borderCollapse: "collapse",
              borderRight: "none",
              borderLeft: "none",
            }}
          >
            <tr style={{ fontWeight: "normal" }}>
              <td style={{ width: "5%", borderRight: "0.5px solid black" }}>
                序号
              </td>
              <td style={{ width: "30%", borderRight: "0.5px solid black" }}>
                配电室名称
              </td>
              <td style={{ width: "15%", borderRight: "0.5px solid black" }}>
                额定电压
              </td>
              <td style={{ width: "17%", borderRight: "0.5px solid black" }}>
                设备型号
              </td>
              <td style={{ width: "17%", borderRight: "0.5px solid black" }}>
                设备运行年限（年）
              </td>
              <td style={{ width: "17%" }}>数量（台）</td>
            </tr>
            {detail?.deviceSummaries?.map((d, i) => {
              return (
                <tr key={nanoid()} style={{ borderTop: "0.5px solid black" }}>
                  <td style={{ borderRight: "0.5px solid black" }}>{i + 1}</td>
                  <td style={{ borderRight: "0.5px solid black" }}>{d.name}</td>
                  <td style={{ borderRight: "0.5px solid black" }}>
                    {d.voltage}
                  </td>
                  <td style={{ borderRight: "0.5px solid black" }}>
                    {d.modal}
                  </td>
                  <td style={{ borderRight: "0.5px solid black" }}>
                    {d.years}
                  </td>
                  <td>{d.count}</td>
                </tr>
              )
            })}
          </table>
          <div style={{ marginTop: 16 }}>风险评估中，抽检的设备如下表。</div>
          <table
            style={{
              width: "100%",
              tableLayout: "fixed",
              border: "2px double black",
              borderCollapse: "collapse",
              borderRight: "none",
              borderLeft: "none",
            }}
          >
            <tr style={{ fontWeight: "normal" }}>
              <td
                key={nanoid()}
                style={{ width: "5%", borderRight: "0.5px solid black" }}
              >
                序号
              </td>
              <td style={{ width: "30%", borderRight: "0.5px solid black" }}>
                配电室名称
              </td>
              <td style={{ width: "15%", borderRight: "0.5px solid black" }}>
                额定电压
              </td>
              <td style={{ width: "17%", borderRight: "0.5px solid black" }}>
                设备型号
              </td>
              <td style={{ width: "17%", borderRight: "0.5px solid black" }}>
                线路名称
              </td>
              <td style={{ width: "17%" }}>备注</td>
            </tr>
            {detail?.data.devices
              ?.flatMap((d) => d.devices)
              ?.map((d, i) => {
                return (
                  <tr key={nanoid()} style={{ borderTop: "0.5px solid black" }}>
                    <td style={{ borderRight: "0.5px solid black" }}>
                      {i + 1}
                    </td>
                    <td style={{ borderRight: "0.5px solid black" }}>
                      {detail?.station?.name}
                    </td>
                    <td style={{ borderRight: "0.5px solid black" }}>
                      {d.device.parameters.find((p) => p.id == "1")?.value}
                    </td>
                    <td style={{ borderRight: "0.5px solid black" }}>
                      {d.device.modal}
                    </td>
                    <td style={{ borderRight: "0.5px solid black" }}>
                      {d.device.pannelName}
                    </td>
                    <td></td>
                  </tr>
                )
              })}
          </table>
        </div>
        <div className="summary">
          <div
            style={{
              height: 8,
              width: 52,
              background: "red",
              clear: "both",
              marginBottom: 32,
            }}
          ></div>
          <h2 style={{ textAlign: "left" }}>评估报告</h2>
          <h3 style={{ marginTop: 8 }}>
            {detail?.station?.name}设备风险评估总结
          </h3>
          <ul style={{ marginTop: 16 }}>
            <li>
              <h4>评估结论:</h4>
              <MarkdownEditor
                options={{
                  cache: {
                    enable: false,
                  },
                }}
                id="SummaryConclusion"
                value={summaries?.SummaryConclusion}
                readOnly={readonly}
                onChange={(e) => {
                  setSummaries((n) => {
                    return {
                      ...n,
                      SummaryConclusion: e,
                    }
                  })
                }}
              />
            </li>
            <li>
              <div style={{ marginTop: 16 }}>总体风险指数图如下：</div>
              <ScatterChart
                data={[
                  {
                    value: [
                      detail?.deviceCharData?.health * 100,
                      detail?.deviceCharData?.importance * 100,
                    ],
                    type: "circle",
                  },
                  {
                    value: [
                      detail?.environmentalCharData?.health * 100,
                      detail?.environmentalCharData?.importance * 100,
                    ],
                    type: "triangle",
                  },
                ]}
                width={"100%"}
                style={{ width: "100%", height: "450px" }}
              />
            </li>
            <li>
              <MarkdownEditor
                options={{
                  cache: {
                    enable: false,
                  },
                }}
                id="DeviceMaintenanceState"
                value={summaries?.DeviceMaintenanceState}
                readOnly={readonly}
                onChange={(e) => {
                  setSummaries((n) => {
                    return {
                      ...n,
                      DeviceMaintenanceState: e,
                    }
                  })
                }}
              />
            </li>
            <li>
              <h4>建议措施:</h4>
              <MarkdownEditor
                options={{
                  cache: {
                    enable: false,
                  },
                }}
                id="DeviceStateScan"
                value={summaries?.DeviceStateScan}
                readOnly={readonly}
                onChange={(e) => {
                  setSummaries((n) => {
                    return {
                      ...n,
                      DeviceStateScan: e,
                    }
                  })
                }}
              />
            </li>
          </ul>
          <h3 style={{ marginTop: 8 }}>{detail?.station?.name}设备风险指数</h3>
          <h4
            style={{
              marginTop: 16,
              borderTop: "2px double black",
              borderBottom: "2px double black",
              backgroundColor: "#f2f2f2",
              padding: 8,
            }}
          >
            {detail?.station?.name}设备风险指数
          </h4>
          <div style={{ display: "flex", padding: 0, margin: 0 }}>
            <div style={{ width: "50%", padding: 16, height: 280, flex: 1 }}>
              <ScatterChart
                data={[
                  {
                    value: [
                      detail?.deviceCharData?.health * 100,
                      detail?.deviceCharData?.importance * 100,
                    ],
                    type: "circle",
                  },
                ]}
                width={"100%"}
                style={{ width: "100%", height: "280px" }}
              />
            </div>
            <div style={{ width: "50%", padding: 0, height: 280, flex: 1 }}>
              <table style={{ border: "none", width: "100%", margin: 0 }}>
                {detail?.stationSummaries.map((s, i) => {
                  return (
                    <tr key={nanoid()}>
                      <td
                        style={{
                          padding: 4,
                          borderTop: i === 0 ? "none" : "0.5px solid black",
                        }}
                      >
                        {s.name}
                      </td>
                      <td
                        style={{
                          padding: 4,
                          borderTop: i === 0 ? "none" : "0.5px solid black",
                          borderLeft: "0.5px solid black",
                        }}
                      >
                        {s.unit}
                      </td>
                      <td
                        style={{
                          padding: 4,
                          borderTop: i === 0 ? "none" : "0.5px solid black",
                          borderLeft: "0.5px solid black",
                        }}
                      >
                        {s.value}
                      </td>
                    </tr>
                  )
                })}
              </table>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              padding: 0,
              margin: 0,
              borderTop: "0.5px solid black",
            }}
          >
            <div style={{ padding: 16, height: 56, flex: 0.5 }}>
              设备整体
              <br />
              风险指数
            </div>
            <div
              style={{
                padding: 16,
                height: 56,
                flex: 1,
                fontSize: 24,
                color: "red",
              }}
            >
              {formatter.format(detail?.deviceCharData?.health ?? 0)}
            </div>
            <div style={{ padding: 16, height: 56, flex: 0.5 }}>
              设备整体
              <br />
              重要性指数
            </div>
            <div
              style={{
                padding: 16,
                height: 56,
                flex: 1,
                fontSize: 24,
                color: "green",
              }}
            >
              {formatter.format(detail?.deviceCharData?.importance)}
            </div>
          </div>
          <DevicesRiskIndex
            detail={detail}
            readonly={readonly}
            // devicesOffset={devicesOffset}
            // setDevicesOffset={setDevicesOffset}
            formatter={formatter}
            ref={DevicesRiskIndexRef}
          />
          {/* <h4
            style={{
              marginTop: 16,
              borderTop: "2px double black",
              borderBottom: "2px double black",
              backgroundColor: "#f2f2f2",
              padding: 8,
            }}
          >
            {detail?.station?.name}设备风险指数
          </h4>
          <table
            style={{
              width: "100%",
              margin: 0,
              tableLayout: "fixed",
            }}
          >
            <tr style={{ fontWeight: "normal" }}>
              <td
                key={nanoid()}
                style={{
                  textAlign: "center",
                  width: "5%",
                  borderRight: "0.5px solid black",
                }}
              >
                序号
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: 4,
                  width: "26%",
                  borderRight: "0.5px solid black",
                }}
              >
                设备名称（线路名称）
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: 4,
                  width: "13%",
                  borderRight: "0.5px solid black",
                }}
              >
                设备型号
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: 4,
                  width: "13%",
                  borderRight: "0.5px solid black",
                }}
              >
                设备重要性指数
              </td>
              <td
                colSpan={2}
                style={{
                  textAlign: "center",
                  borderRight: "0.5px solid black",
                  padding: 4,
                  width: "22%",
                }}
              >
                设备风险指数
              </td>

              <td
                style={{
                  textAlign: "center",
                  padding: 4,
                  width: "10%",
                  borderRight: "0.5px solid black",
                }}
              >
                偏移量
              </td>
            </tr>
            {detail?.data.devices
              ?.flatMap((d) => d.devices)
              ?.map((d, i) => {
                return (
                  <tr key={d?.device?.id || i}>
                    <td
                      style={{
                        textAlign: "center",
                        padding: 4,
                        borderTop: "0.5px solid black",
                        borderRight: "0.5px solid black",
                      }}
                    >
                      {i + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        padding: 4,
                        borderTop: "0.5px solid black",
                        borderRight: "0.5px solid black",
                      }}
                    >
                      {d.device?.displayName}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        padding: 4,
                        borderTop: "0.5px solid black",
                        borderRight: "0.5px solid black",
                      }}
                    >
                      {d.device.modal}%
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        padding: 4,
                        borderTop: "0.5px solid black",
                        borderRight: "0.5px solid black",
                      }}
                    >
                      {formatter.format(d?.beforeChart?.importance)}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        padding: 4,
                        borderTop: "0.5px solid black",
                        tableLayout: "fixed",
                        width: "20px",
                        borderRight: "0.5px solid black",
                      }}
                    >
                      {formatter.format(d?.beforeChart?.health)}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        padding: 4,
                        width: "30%",
                        borderTop: "0.5px solid black",
                        borderRight: "0.5px solid black",
                      }}
                    >
                      {d?.beforeChart?.healthPlace?.name}
                    </td>

                    <td
                      style={{
                        textAlign: "center",
                        padding: 4,
                        borderTop: "0.5px solid black",
                        borderRight: "0.5px solid black",
                      }}
                    >
                      <NumericInput
                        min={-100}
                        max={100}
                        disabled={readonly}
                        disablePlus={readonly}
                        disableMinus={readonly}
                        disableEdit={readonly}
                        suffix={<span>%</span>}
                        value={
                          devicesOffset[d?.device?.id]?.summaries
                            ?.AfterOffset || 0
                        }
                        onChange={(newValue) => {
                          setDevicesOffset((n) => {
                            n[[d?.device?.id]].summaries.AfterOffset = newValue
                            return {
                              ...n,
                            }
                          })
                        }}
                      />
                    </td>
                  </tr>
                )
              })}
          </table> */}
          {/* <h4
            style={{
              marginTop: 0,
              borderTop: "2px double black",
              borderBottom: "2px double black",
              backgroundColor: "#f2f2f2",
              padding: 8,
            }}
          >
            主要风险隐患
          </h4>
          <table
            style={{
              width: "100%",
              margin: 0,
              tableLayout: "fixed",
              borderBottom: "2px double black",
            }}
          >
            <tr style={{ fontWeight: "normal" }}>
              <td
                key={nanoid()}
                style={{ width: "5%", borderRight: "0.5px solid black" }}
              >
                序号
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: 4,
                  width: "70%",
                  borderRight: "0.5px solid black",
                }}
              >
                图片
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: 4,
                  width: "30%",
                  borderRight: "0.5px solid black",
                }}
              >
                备注
              </td>
            </tr>
            {uniqBy(
              detail?.data.devices
                ?.flatMap((d) => d.devices)
                .flatMap((d) => d.data)
                .flatMap((d) => d.result.checkItems)
                .flatMap((c) => c.points)
                .filter((o) => o.result.code === "2" && getValue(o) >= 7),
              "id"
            )?.map((d, i) => {
              console.log(d, "dddddddddddd")
              return (
                <tr key={d.id}>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    {i + 1}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                      display: "flex",
                    }}
                  >
                    <div style={{ flex: 1, verticalAlign: "middle" }}>
                      {getText(d)}
                    </div>
                    <div style={{ flex: 1 }}>
                      <Image
                        id={d.result.beforPhoto}
                        style={{ maxWidth: 230, maxHeight: 230 }}
                      />
                    </div>
                  </td>
       
                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: 4,
                        right: 4,
                        left: 4,
                        bottom: 4,
                      }}
                    >
                      <textarea
                        style={{ height: "100%", resize: "none" }}
                        id={d.id}
                        value={devicesDanger?.[d.id]}
                        readOnly={readonly}
                        onChange={(e) =>
                          setDevicesDanger((n) => {
                            return {
                              ...n,
                              [d.id]: e.target.value,
                            }
                          })
                        }
                        name={d.id}
                        className="k-textbox"
                        rows="4"
                      ></textarea>
                    </div>
                  </td>
                </tr>
              )
            })}
          </table> */}
          <DevicesDangerTB
            ref={DevicesDangerTBRef}
            data={devicesDangerData}
            // setDevicesDanger={setDevicesDanger}
            getText={getText}
            // devicesDanger={devicesDanger}
            readonly={readonly}
            projectId={detail?.project?.id}
          />
          <ul style={{ marginTop: 16 }}>
            <li>
              <h4>小结:</h4>
              <MarkdownEditor
                options={{
                  cache: {
                    enable: false,
                  },
                }}
                id="DeviceProblemSummaryRemark"
                value={summaries?.DeviceProblemSummaryRemark}
                readOnly={readonly}
                onChange={(e) => {
                  setSummaries((n) => {
                    return {
                      ...n,
                      DeviceProblemSummaryRemark: e,
                    }
                  })
                }}
              />
            </li>
          </ul>
          <h3 style={{ marginTop: 8 }}>
            {detail?.station?.name}运行环境风险指数
          </h3>
          <h4
            style={{
              marginTop: 16,
              borderTop: "2px double black",
              borderBottom: "2px double black",
              backgroundColor: "#f2f2f2",
              padding: 8,
            }}
          >
            {detail?.station?.name}运行环境风险指数
          </h4>
          <div style={{ display: "flex", padding: 0, margin: 0 }}>
            <div style={{ width: "50%", padding: 16, height: 280, flex: 1 }}>
              <ScatterChart
                data={[
                  {
                    value: [
                      detail?.environmentalCharData?.health * 100,
                      detail?.environmentalCharData?.importance * 100,
                    ],
                    type: "circle",
                  },
                ]}
                width={"100%"}
                style={{ width: "100%", height: "280px" }}
              />
            </div>
            <div style={{ width: "50%", padding: 0, height: 280, flex: 1 }}>
              <table style={{ border: "none", width: "100%", margin: 0 }}>
                {detail?.stationSummaries.map((s, i) => {
                  return (
                    <tr key={nanoid()}>
                      <td
                        style={{
                          padding: 4,
                          borderTop: i === 0 ? "none" : "0.5px solid black",
                        }}
                      >
                        {s.name}
                      </td>
                      <td
                        style={{
                          padding: 4,
                          borderTop: i === 0 ? "none" : "0.5px solid black",
                          borderLeft: "0.5px solid black",
                        }}
                      >
                        {s.unit}
                      </td>
                      <td
                        style={{
                          padding: 4,
                          borderTop: i === 0 ? "none" : "0.5px solid black",
                          borderLeft: "0.5px solid black",
                        }}
                      >
                        {s.value}
                      </td>
                    </tr>
                  )
                })}
              </table>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              padding: 0,
              margin: 0,
              borderTop: "0.5px solid black",
            }}
          >
            <div style={{ padding: 16, height: 56, flex: 0.5 }}>
              运行环境
              <br />
              风险指数
            </div>
            <div
              style={{
                padding: 16,
                height: 56,
                flex: 1,
                fontSize: 24,
                color: "red",
              }}
            >
              {formatter.format(detail?.environmentalCharData?.health)}
            </div>
            <div style={{ padding: 16, height: 56, flex: 0.5 }}>
              设备整体
              <br />
              重要性指数
            </div>
            <div
              style={{
                padding: 16,
                height: 56,
                flex: 1,
                fontSize: 24,
                color: "green",
              }}
            >
              {formatter.format(detail?.environmentalCharData?.importance)}
            </div>
          </div>
          <h4
            style={{
              marginTop: 16,
              borderTop: "2px double black",
              borderBottom: "2px double black",
              backgroundColor: "#f2f2f2",
              padding: 8,
            }}
          >
            运行环境风险指数组成
          </h4>
          <table
            style={{
              width: "100%",
              margin: 0,
              tableLayout: "fixed",
            }}
          >
            <tr style={{ fontWeight: "normal" }}>
              <td
                key={nanoid()}
                style={{ width: "5%", borderRight: "0.5px solid black" }}
              >
                序号
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: 4,
                  width: "30%",
                  borderRight: "0.5px solid black",
                }}
              >
                检查类别
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: 4,
                  width: "17%",
                  borderRight: "0.5px solid black",
                }}
              >
                风险指数
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: 4,
                  width: "17%",
                  borderRight: "0.5px solid black",
                }}
              >
                风险级别
              </td>
            </tr>
            {detail?.station.parameters?.map((d, i) => {
              return (
                <tr key={nanoid()}>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    {i + 1}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    {d.name}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    {formatter.format(d?.charData?.health)}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 4,
                      borderTop: "0.5px solid black",
                      borderRight: "0.5px solid black",
                    }}
                  >
                    {d?.charData?.healthPlace?.name}
                  </td>
                </tr>
              )
            })}
          </table>
          <h4
            style={{
              marginTop: 0,
              borderTop: "2px double black",
              borderBottom: "2px double black",
              backgroundColor: "#f2f2f2",
              padding: 8,
            }}
          >
            主要风险隐患
          </h4>
          <table
            style={{
              width: "100%",
              margin: 0,
              tableLayout: "fixed",
              borderBottom: "2px double black",
            }}
          >
            <tr style={{ fontWeight: "normal" }}>
              <td
                key={nanoid()}
                style={{ width: "5%", borderRight: "0.5px solid black" }}
              >
                序号
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: 4,
                  width: "46%",
                  borderRight: "0.5px solid black",
                }}
              >
                图片
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: 4,
                  width: "46%",
                  borderRight: "0.5px solid black",
                }}
              >
                风险说明
              </td>
            </tr>
            {
              // detail?.station.parameters
              // ?.flatMap((d) => d.datas)
              // .flatMap((d) => d.parameters)
              // .flatMap((d) => Object.values(d))
              // // .flatMap((d) => {

              // //   const v=Object.values(d)
              // //   console.log('v',v);
              // //   return v
              // // })

              // .filter(
              //   (o) =>
              //     o.options.find((i) => i.id === o?.result?.option)?.key ===
              //       "2" && getValue(o) > 6
              // )
              // //.filter((o) =>getValue(o)>=7)
              stationEnvironmentalDanger?.map((d, i) => {
                return (
                  <tr key={nanoid()}>
                    <td
                      style={{
                        textAlign: "center",
                        padding: 4,
                        borderTop: "0.5px solid black",
                        borderRight: "0.5px solid black",
                      }}
                    >
                      {i + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        padding: 4,
                        borderTop: "0.5px solid black",
                        borderRight: "0.5px solid black",
                        display: "flex",
                      }}
                    >
                      <div style={{ flex: 1, verticalAlign: "middle" }}>
                        {" "}
                        {getText(d)}{" "}
                      </div>
                      <div style={{ flex: 1 }}>
                        <Image
                          id={d.result.beforPhoto}
                          style={{ maxWidth: 230, maxHeight: 230 }}
                        />
                      </div>
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        padding: 4,
                        borderTop: "0.5px solid black",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 4,
                          right: 4,
                          left: 4,
                          bottom: 4,
                        }}
                      >
                        <textarea
                          style={{ height: "100%", resize: "none" }}
                          id={d.id}
                          // value={devicesDanger?.[d.id]}
                          defaultValue={
                            stationEnvironmentalDanger?.[i].description
                          }
                          readOnly={readonly}
                          onBlur={(e) => {
                            setStationEnvironmentalDanger((n) => {
                              n[i].description = e.target.value
                              return n
                            })
                          }}
                          name={d.id}
                          className="k-textbox"
                          rows="4"
                        ></textarea>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </table>
          <ul style={{ marginTop: 16 }}>
            <li>
              <h4>小结:</h4>
              <MarkdownEditor
                options={{
                  cache: {
                    enable: false,
                  },
                }}
                id="ExpProblemSummaryRemark"
                value={summaries?.ExpProblemSummaryRemark}
                readOnly={readonly}
                onChange={(e) => {
                  setSummaries((n) => {
                    return {
                      ...n,
                      ExpProblemSummaryRemark: e,
                    }
                  })
                }}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  )
})
