import { useParam } from 'hooks/useParam'
import React, { useEffect } from 'react'
import { getReportView } from 'common/apis/ezreport'
import axios from 'axios'
import { useState } from 'react'
import TotalReportView from './TotalReportView'
export default function ReportView() {
  const [detail, setDetail] = useState(null)
  const id = useParam('id')

  const fetchPreviewInfo = (reportId) => {
    axios.get(getReportView(reportId)).then((response) => {
      setDetail(response.data)
    })
  }
  useEffect(() => {
    if (id) {
      fetchPreviewInfo(id)
    }
  }, [id])

  return (
    <div style={{ width: '100%', overflow: 'visible', display: 'flex',flexDirection:'column', backgroundColor: '#fff' }}>
      {detail && detail.type === 'TotalReport' && (
        <TotalReportView onRefresh={()=>fetchPreviewInfo(id)} detail={detail}/>
      )}
    </div>
  )
}
