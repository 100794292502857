import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { CommonIndex, CommonTable } from 'components/LoongInCommon'
import { Button, Dialog } from '@abb/abb-common-ux-react'
import { FileSelector } from 'components/FileSelector'
import { getMaintenanceRangeTree, setMaintenanceRangeBind } from 'common/apis/maintenanceRange'
import { forwardRef } from 'react'
import { useImperativeHandle } from 'react'
import { t } from 'i18next'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import { useDispatch } from 'react-redux'

export const SearchItem = forwardRef(({ onRefresh }, ref) => {
  const [activeItem, setActiveItem] = useState(null)
  const [activeRange, setActiveRange] = useState(null)
  const refIndex = useRef(null)
  const devTypRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const [data, setData] = useState([])
  const [allList, setAllList] = useState([])

  const [checkedItems, setCheckedItems] = useState([])
  const [checkedItems2, setCheckedItems2] = useState([])
  const dispatch = useDispatch()

  const [searchData, setSearchData] = useState(null)
  const childrenColumns = [
    {
      title: 'maintenanceCatalog.childrenTitle',
      key: 'title',
      width: '300',
    },
  ]

  const columns = [
    {
      title: 'maintenanceCatalog.title',
      key: 'title',
      textAlign: 'center',
      expand: (item, index) => {
        const list = item.children
        if (list.length == 0) return <div style={{ marginLeft: '20px' }}>没有数据</div>
        return (
          <div style={{ padding: '10px 20px 10px 20px' }}>
            <CommonTable
              zebra={true}
              columns={childrenColumns}
              checkedItems={checkedItems2}
              canSelection={true}
              data={list}
              showPage={false}
              onSelectionChange={(items) => {
                const d = checkedItems.filter((c) => !list.find((l) => l == c))
                setCheckedItems([...d, ...items])
              }}
              keyField='key'
            />
          </div>
        )
      },
    },
  ]
  const showDialog = (projectId, rangeId, deviceTypes, checkedItems) => {
    console.log(checkedItems)
    setData(checkedItems)
    setCheckedItems2(checkedItems.map((i) => i.itemId))
    setCheckedItems(allList.filter((l) => !!checkedItems.find((i) => i == l.key)))
    setActiveItem(projectId)
    setActiveRange(rangeId)
    setSearchData({
      deviceTypes,
      projectType: '8',
    })
    setIsVisible(true)
  }

  useEffect(() => {
    if (checkedItems2 && allList) {
      setCheckedItems(allList.filter((l) => !!checkedItems2.find((i) => i == l.key)))
    }
  }, [checkedItems2, allList])
  //传递引用方法
  useImperativeHandle(ref, () => ({
    // setIsVisible,
    // setSearchData,
    showDialog,
  }))
  const getResult = (data) => {
    let list = []
    data.forEach((i) => {
      list = [...list, ...i.children]
    })
    setAllList(list)
    return data
  }
  const saveBind = () => {
    console.log(data)
    console.log(checkedItems)
    const datas = checkedItems.map((i) => {
      return {
        itemId: i.key,
        deviceTypeId: i.deviceTypeId,
        maintenanceType: i.type,
      }
    })
    const request = {
      projectId: activeItem,
      rangeId: activeRange,
      items: datas,
    }
    axios.post(setMaintenanceRangeBind(), request).then((response) => {
      setIsVisible(false)
      onRefresh && onRefresh()
    })
  }

  const onConfirm = () => {
    if (!checkedItems || checkedItems.length <= 0) {
      dispatch(showNotification(NotificationType.Alarm, '请选择评估项'))
      return
    }
    console.log(checkedItems)
    saveBind()
    // addUsers(
    //   checkItems.map((item) => {
    //     return item.id
    //   }),
    //   () => {
    //     setIsVisible(false)
    //     onRefresh && onRefresh()
    //   }
    // )
  }
  const onCancel = () => {
    setIsVisible(false)
  }
  //返回页面
  return (
    <>
      <Dialog
        style={{ width: '960px', height: '800px' }}
        showCloseButton={true}
        closeOnLostFocus
        closeOnEscape={false}
        dimBackground={true}
        isOpen={isVisible}
        title={'选择评估项'}
        standardButtonsOnBottom={[
          {
            text: t('b_confirm'),
            type: 'primary-blue',
            handler: onConfirm,
          },
          {
            text: t('b_cancel'),
            type: 'normal',
            handler: onCancel,
          },
        ]}
        onClose={() => setIsVisible(false)}
      >
        <div style={{ height: '640px' }}>
          <CommonIndex
            tableWidth={'100%'}
            tableHeight={600}
            ref={refIndex}
            keyField='key'
            canSearch={false}
            columns={columns}
            getList={getMaintenanceRangeTree}
            getResult={getResult}
            showPage={false}
            searchData={searchData}
            refreshAfterDelete={true}
            enableSorting={false}
            refreshWhenOpen={false}
            canSelection={false} //是否开启选择功能
            canBatchDel={false}
            searchClassName='searching-form-simple' //查询样式
            toolClassName='searching-form-simple searching-form-right-tool text-right' //工具栏样式
          />
        </div>
      </Dialog>
    </>
  )
})
