import React, { useState, forwardRef, useImperativeHandle } from "react"
import { Dialog, LoadingIndicator } from "@abb/abb-common-ux-react"
import { updateTotalReport } from "common/apis/totalreports"
import "../../../../styles/totalReport.scss"

import axios from "axios"
import { useRef } from "react"
import { TotalReport } from "../components/TotalReport"
export const EditTotalReport = forwardRef(({ onSubmit }, ref) => {
  const [isVisible, setIsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [report, setReport] = useState(null)
  const reportView = useRef(null)
  const showDialog = (report) => {
    setReport(report)
    setIsVisible(true)
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    showDialog,
  }))

  const save = (subbmit) => {
    const summaries = reportView.current.getSummaries()
    const sOffset = reportView.current.getDevicesOffset()
    const devCheckPoint = reportView.current.getDevCheckPoint()
    const stationCheckPoint = reportView.current.getStationCheckPoint()
    setIsLoading(true)
    axios
      .post(updateTotalReport(report?.id), {
        summaries: summaries,
        submit: subbmit,
        sOffset,
        devCheckPoint,
        stationCheckPoint,
      })
      .then((r) => {
        setIsLoading(false)
        onSubmit && onSubmit()
        setIsVisible(false)
      })
  }
  return (
    <Dialog
      style={{ width: "1200px", height: "700px" }}
      showCloseButton={true}
      closeOnLostFocus={false}
      closeOnEscape={false}
      dimBackground={true}
      isOpen={isVisible}
      title={report?.name}
      onClose={() => setIsVisible(false)}
      standardButtonsOnBottom={
        isLoading
          ? []
          : [
              {
                text: "保存",
                type: "primary-black",
                handler: (dlg) => save(false),
              },
              {
                text: "提交",
                type: "primary-blue",
                handler: (dlg) => save(true),
              },
              { text: "取消", handler: (dlg) => dlg.close() },
            ]
      }
    >
      {!isLoading && (
        <TotalReport ref={reportView} report={report} readonly={false} />
      )}
      {isLoading && (
        <div style={{ flex: 1 }}>
          <div className="central-position">
            <LoadingIndicator sizeClass="small" color="blue" type="radial" />{" "}
          </div>
        </div>
      )}
    </Dialog>
  )
})
