import { viewFile } from 'common/apis/maindata'
import React from 'react'
import { useSelector } from 'react-redux'
import { getEnv } from 'slices/envSlice'
import nofile from './nofile.gif'
import breakfile from './break.svg'

export  const Image=({ id ,style,defaultImage=null}) =>{
    const env = useSelector(getEnv)

    const path =id? `${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${viewFile()}?id=${id}`:(defaultImage?? nofile)
    return <img alt='图片' src={path}  onError={e=>e.target.src=(defaultImage?? breakfile)} style={style}/>
}
