import React, { useState, forwardRef, useImperativeHandle } from "react"
import { Dialog, LoadingIndicator } from "@abb/abb-common-ux-react"
import { approveTotalReport } from "common/apis/totalreports"
import "../../../../styles/totalReport.scss"

import axios from "axios"
import { TotalReport } from "../components/TotalReport"
export const BackTotalReport = forwardRef(({ onSubmit }, ref) => {
  const [isVisible, setIsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [report, setReport] = useState(null)
  const [suggestion, setSuggestion] = useState("")
  const showDialog = (report) => {
    setReport(report)
    setIsVisible(true)
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    showDialog,
  }))

  const save = (status) => {
    setIsLoading(true)
    axios
      .post(approveTotalReport(report.id), {
        suggestion: suggestion,
        status: status,
      })
      .then((r) => {
        setIsLoading(false)
        onSubmit && onSubmit()
        setIsVisible(false)
      })
  }

  return (
    <Dialog
      style={{ width: "1200px", height: "700px" }}
      showCloseButton={true}
      closeOnLostFocus={false}
      closeOnEscape={false}
      dimBackground={true}
      isOpen={isVisible}
      title={`${report?.name}【退回】`}
      onClose={() => setIsVisible(false)}
      standardButtonsOnBottom={
        isLoading
          ? []
          : [
              {
                text: "退回报告",
                type: "primary-red",
                handler: (dlg) => save(-2),
              },
              { text: "取消", handler: (dlg) => dlg.close() },
            ]
      }
    >
      {!isLoading && (
        <>
          <TotalReport report={report} readonly={true} />
          <div id="approvePart">
            <h4>总体报告退回意见</h4>
            <textarea
              value={suggestion}
              onChange={(e) => setSuggestion(e.target.value)}
              rows="3"
              class="k-textbox"
              placeholder="总体报告退回意见内容......"
            ></textarea>
          </div>
        </>
      )}
      {isLoading && (
        <div style={{ flex: 1 }}>
          <div className="central-position">
            <LoadingIndicator sizeClass="small" color="blue" type="radial" />{" "}
          </div>
        </div>
      )}
    </Dialog>
  )
})
