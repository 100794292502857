import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { LoadingIndicator } from '@abb/abb-common-ux-react'

import { useDispatch, useSelector } from 'react-redux'
import { ErrorBoundary } from 'common/ErrorBoundary'
import {
  fetchStationAsync,
  getStation,
} from 'slices/deviceSlice'
import DeviceDoc from './DeviceDoc'
import './dashboard.scss'

export default function Station() {
  const urlParams = useParams()
  const dispatch = useDispatch()
  const station = useSelector(getStation)
  const id = urlParams.id
  useEffect(() => {
    if (urlParams.id) {
      console.log(urlParams.id)
      dispatch(fetchStationAsync({ id: urlParams.id }))
    }
  }, [urlParams.id])


  if (station) {
    return (
      <ErrorBoundary>
        <div className='grid root-grid' data-cy='module-device-dashboard'>
          <div className='row abb-col-lg-24 abb-col-md-24 abb-col-ms-24'>
            <div className='grid' style={{ margin: 0 }}>
              <div className='row  abb-col-lg-24 abb-col-md-24 abb-col-ms-24'>
                <div className='tile height-12x  abb-col-lg8-24 abb-col-md8-24 abb-col-ms8-24'>
                  <DeviceDoc device={station} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    )
  }
  return (
    <div className='grid root-grid'>
      <p>您没有访问此页面的权限</p>
    </div>
  )
  //返回页面
}
