import React, { useState, useEffect, useRef } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { CommonList } from 'components/LoongInCommon'
import { MainIndex } from './MainIndex'
import { TypeFormEdit } from './TypeFormEdit'
import axios from 'axios'
import { t } from 'i18next'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import { useDispatch } from 'react-redux'
import { CommonConfirm } from 'components/LoongInCommon'
import { deleteStationParameterCatalog, getStationParameterCatalogPage } from 'common/apis/ezreport'

export default () => {
  const { path } = useRouteMatch()
  const [list, setList] = useState(null)
  const [activeItem, setActiveItem] = useState([])
  const [isLoadingList, setIsLoadingList] = useState(false)
  const typeFormRef = useRef(null)
  const [typeFormData, setTypeFormData] = useState(null)
  const [activDataIndex, setActivDataIndex] = useState('')
  const [deleteId, setDeleteId] = useState(null)
  const [isDeleting, setIsDeleting] = useState(null)
  const refList = useRef(null)
  const dispatch = useDispatch()
  const [typeFormEditEvent, setTypeFormEditEvent] = useState(null)

  const onTypeFormSumbit = () => {
    if (activDataIndex) {
      setActivDataIndex('')
      setTypeFormData(null)
      getAllList()
    } else {
      getAllList()
    }
  }
  const getAllList = () => {
    setIsLoadingList(true)
    axios
      .post(getStationParameterCatalogPage(), { pageSize: 10000, key: '8' })
      .then((response) => {
        if (response.status == 200 && response.data) {
          setList(response.data.items)
          if (response.data.items.length > 0) {
            refList.current.setCurrentItem(response.data.items[0])
          } else setActiveItem({})
        } else {
          setList([])
        }
      })
      .finally(() => {
        setIsLoadingList(false)
      })
  }

  useEffect(() => {
    getAllList()
  }, [])
  const onItemChange = (item) => {
    setActiveItem(item)
  }
  const onSubmit = () => {
    getAllList()
  }
  //添加按钮
  const rightIcon = {
    name: 'abb/plus',
    color: 'blue',
    sizeClass: 'medium',
    onClick: () => {
      setTypeFormData({
        code: '',
        description: '',
        displayText: '',
      })
      setActivDataIndex('')
      setTypeFormEditEvent({
        name: 'setShowSidePanel',
        params: [true],
      })
      // typeFormRef.current.setShowSidePanel(true)
    },
  }
  const myMenu = [
    {
      title: t('b_edit'),
      color: 'blue',
      onClick: (item) => {
        setTypeFormData({
          id: item.id,
          name: item.name,
          weight: item.weight,
        })
        setActivDataIndex(item.id)
        setTypeFormEditEvent({
          name: 'setShowSidePanel',
          params: [true],
        })
        // typeFormRef.current.setShowSidePanel(true)
      },
    },
    {
      title: t('b_del'),
      color: 'red',
      onClick: (item) => {
        setDeleteId(item.id)
      },
    },
  ]

  const onDeleteConfirm = async () => {
    try {
      setIsDeleting(true)
      const response = await axios.post(deleteStationParameterCatalog(), null, { params: { id: deleteId } })
      if (response.status === 200 || response.status === 204) {
        setDeleteId(null)
        getAllList()
        dispatch(showNotification(NotificationType.Success, t('t_hasDelete')))
      } else {
        dispatch(showNotification(NotificationType.Warn, t('t_deleteFail')))
      }
    } catch (e) {
      dispatch(showNotification(NotificationType.Alarm, t('t_deleteFail')))
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        padding: 8,
        boxSizing: 'border-box',
      }}
      data-cy='admin-device-model'
    >
      <Route path={[`${path}/:id`, path]}>
        <CommonList
          style={{ margin: 0 }}
          ref={refList}
          data={list}
          keyName='id'
          filterText='name'
          displayName='name'
          isLoading={isLoadingList}
          onItemChange={onItemChange}
          rightIcon={rightIcon}
          contexMenu={myMenu}
        />
        <TypeFormEdit
          ref={typeFormRef}
          formData={typeFormData}
          onCancel={() => {
            setActivDataIndex('')
            setTypeFormData(null)
          }}
          id={activDataIndex}
          onSubmit={onTypeFormSumbit}
          event={typeFormEditEvent}
        />
        <MainIndex catalogId={activeItem.id} catalogName={activeItem.name} onSubmit={onSubmit} />
        <CommonConfirm
          title={t('t_titleDeleteData')}
          content={t('t_confirmDeleteData')}
          isVisible={deleteId != null}
          onConfirm={onDeleteConfirm}
          confirmText={t('b_confirm')}
          onCancel={() => setDeleteId(null)}
          isLoading={isDeleting}
        />
      </Route>
    </div>
  )
}
