import React, { useState, useEffect, useRef } from "react";
import {
    WithTooltip,
    Tooltip,
    Icon,
    Button,
    Tag,
} from "@abb/abb-common-ux-react";
import { getUserProfile } from "slices/userProfileSlice";
import { useSelector } from "react-redux";
// import { CommonConfirm } from "components/LoongInCommon"
import { CommonIndex } from "components/LoongInCommon";

import { useDispatch } from "react-redux";
import { t } from "i18next";
import {
    getWechatShare,
    updateWechatShare,
    getWechatUsers,
} from "common/apis/wechat";
import { getEnv } from "slices/envSlice";
import { viewFile } from "common/apis/maindata";
import { useMountedState } from "common/useMountedState";
import axios from "axios";
import {
    NotificationType,
    showNotification,
} from "components/Notification/notificationSlice";
import { getUsers } from "slices/userSlice";
//import { SearchUser } from './SearchUser'
import {
    DropdownButton,
    DropdownButtonOption,
} from "components/DropdownButton";

import nofile from "./nofile.gif";
import moment from 'moment';

export default function Factories() {
    const userProfile = useSelector(getUserProfile);
    // const [updateUserRole, setUpdateUserRole] = useState(null)
    const [deletedItems, setDeletedItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [checkItems, setCheckItems] = useState([]);
    const dispatch = useDispatch();
    const isMounted = useMountedState();
    const users = useSelector(getUsers);
    const searchUserRef = useRef(null);
    const env = useSelector(getEnv);
    const refIndex = useRef(null);
    const sharetype = {
        0: "Link",
        1: "Music",
        2: "Video",
    };
    const shareStatus = {
        0: "禁止",
        1: "允许",
    };

    //列定义
    const columns = [
        {
            title: "分享用户",
            key: "shareOpenId",
            render: (item, index) => {
                return item.users?.nickName;
                //const manager = item.users ? item.users.find((d) => d.id === item.shareOpenId) : null
                //return manager?.NickName
            },
        },
        {
            title: "分享标题",
            key: "title",
        },
        {
            title: "分享描述",
            key: "description",
        },
        {
            title: "分享状态",
            key: "status",
            render: (item, index) => {
                return shareStatus[item.status];
            },
        },
        {
            title: "分享时间",
            render: (item) => {
                return moment(item.creationTime).format('yyyy-MM-DD HH:mm:ss')
            },
        },
        {
            title: "设置有效时间",
            render: (item) => {
                return moment(item.deadlineTime).format('yyyy-MM-DD HH:mm:ss')
            },
        },
        {
            title: "分享链接",
            key: "docId",
            //   width: 500,
            render: (item, index) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 8,
                        }}
                    >
                        <img
                            style={{
                                width: 32,
                                height: 32,
                                scale: "revert",
                                borderRadius: "50%",
                                objectFit: "contain",
                            }}
                            src={item.imgUrl || nofile}
                            onClick={(e) =>
                                window.open(
                                    `${env.apiBase + (env.apiBase.endsWith("/") ? "" : "/")
                                    }${viewFile()}?id=${item.docId}`
                                )
                            }
                        />
                        <span>{`${item.link}?id=${item.id}`}</span>
                    </div>
                );
            },
        },
        //{
        //    title: '分享图标',
        //    key: 'ImageUrl',
        //    render: (item, index) => {
        //        return (
        //            <>
        //                <img style={{ width: 32, height: 32, scale: 'revert', borderRadius: '50%', objectFit: 'contain' }} src={item.imgUrl ?? header} />
        //                <span style={{ marginLeft: 8 }}>{item?.nickName}</span>
        //            </>
        //        )
        //    },
        //},
    ];

    const setTags = (action) => {
        setIsLoading(true);
        const payload = {
            zt: action,
            Ids: checkItems.map((i) => i.id),
        };
        axios
            .put(updateWechatShare(), payload)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    refIndex.current.query();
                    dispatch(
                        showNotification(NotificationType.Success, t("t_successToSet"))
                    ); //`设置成功`
                } else {
                    dispatch(
                        showNotification(NotificationType.Warn, response.data.message)
                    );
                }
            })
            .catch((e) => {
                dispatch(
                    showNotification(
                        NotificationType.Alarm,
                        e && e.error && e.error.message
                            ? e.error.message
                            : `设置分享状态失败`
                    )
                );
            })
            .finally(() => {
                setIsLoading(false);
                setCheckItems([]);
            });
    };
    const onSelectionChange = (items) => {
        // console.log(items)
        setCheckItems(items);
    };
    //删除时放入删除项
    const onDeleteData = (deleteId) => {
        setDeletedItems([...deletedItems, deleteId]);
    };
    const toolChildren = (
        <>
            <DropdownButton
                disabled={!checkItems || checkItems.length <= 0}
                title={"批量设置"}
                icon={"abb/label"}
            >
                <Button
                    style={{ width: "100%" }}
                    text="启用"
                    isLoading={isLoading}
                    type="ghost"
                    sizeClass="small"
                    onClick={() => setTags(1)}
                />
                <Button
                    text="禁用"
                    style={{ width: "100%" }}
                    type="ghost"
                    sizeClass="small"
                    onClick={() => setTags(0)}
                />{" "}
            </DropdownButton>
        </>
    );

    //返回页面
    return (
        <>
            <CommonIndex
                ref={refIndex}
                columns={columns}
                isLoading={isLoading}
                canSelection={true}
                toolWidth={280}
                getList={getWechatShare}
                toolChildren={toolChildren}
                canAdd={false}
                canEdit={false}
                canDelete={false}
                onDeleteData={onDeleteData}
                onSelectionChange={onSelectionChange}
                enableSorting={true}
            />
        </>
    );
}
