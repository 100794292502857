import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import {
  getDevice,
  getParent,
  getDeviceType,
  getStation,
} from "slices/deviceSlice"
import { getFactories } from "slices/factorySlice"
import {
  Table,
  Tooltip,
  WithTooltip,
  Button,
  Dialog,
} from "@abb/abb-common-ux-react"
import { nanoid } from "nanoid"
import { t } from "i18next"
import moment from "moment"
import { SlidingCard } from "components/SlidingCard"
import QRCode from "qrcode"
import axios from "axios"
import { getQrCode } from "common/apis/maindata"
export default function DeviceInfo({ onEdit }) {
  const device = useSelector(getDevice)
  const parent = useSelector(getParent)
  const station = useSelector(getStation)
  const type = useSelector(getDeviceType)
  const [isVisible, setIsVisible] = useState(false)
  const [generateQrCodeLoading, setGenerateQrCodeLoading] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const factories = useSelector(getFactories)
  const [qrCode, setQrCode] = useState(null)
  let profiles = []
  const factory = factories?.find((f) => f.id == device?.factoryId)
  profiles = [
    [
      { title: t("device.displayName"), value: device.displayName },
      { title: t("device.station"), value: station?.name },
      { title: t("device.type"), value: type.name },
      { title: t("device.parentName"), value: parent?.displayName ?? "无" },
      { title: t("device.sn"), value: device.sn },
      { title: t("device.orderNumber"), value: device.orderNumber },
      { title: t("device.factory"), value: factory?.name },
      {
        title: t("device.productDate"),
        value: moment(device.productDate).format("yyyy-MM-DD"),
      },
    ],
    [
      { title: t("device.pannelNo"), value: device.pannelNo },
      { title: t("device.pannelName"), value: device.pannelName },
      { title: t("device.busNo"), value: device.busNo },
      { title: t("device.description"), value: device.description },
    ],
  ]

  useEffect(() => {
    window.addEventListener("resize", onWindowResize)
    return () => {
      window.removeEventListener("resize", onWindowResize)
    }
  }, [])
  const onWindowResize = () => {
    setPageIndex(0)
  }

  const getQrCodeData = async () => {
    const res = await axios.post(getQrCode(device?.id))
    QRCode.toDataURL(
      res.data,
      {
        type: "png",
        color: {
          dark: "#1f1f1f",
          light: "#fff",
        },
      },
      function (err, url) {
        setQrCode(url)
      }
    )
  }
  useEffect(() => {
    getQrCodeData()
    console.log(device, "device")
  }, [])
  return (
    <>
      <SlidingCard>
        {profiles.map((list) =>
          list.length === 0 ? null : (
            <Table
              sizeClass="medium"
              title={t("device.formTitle")}
              borderType="discreet"
              key={nanoid()}
              otherButton={[
                <WithTooltip>
                  <Button
                    sizeClass="small"
                    shape="pill"
                    type="discreet-black"
                    icon="abb/qr-code"
                    onClick={() => {
                      setIsVisible(true)
                      console.log(list, "list")
                    }}
                    style={{ opacity: 1, marginRight: 8 }}
                  />
                  <Tooltip>查看二维码</Tooltip>
                </WithTooltip>,
              ]}
            >
              <tbody>
                {list.map((item) => (
                  <tr key={nanoid()}>
                    <td title={item.title}>{item.title}</td>
                    <td>
                      {item.value && (
                        <WithTooltip>
                          <span>{item.value}</span>
                          <Tooltip>
                            <div>
                              {item.title}：{item.value}
                            </div>
                          </Tooltip>
                        </WithTooltip>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )
        )}
      </SlidingCard>
      <Dialog
        showCloseButton={true}
        title={"查看设备二维码"}
        closeOnEscape
        closeOnLostFocus
        dimBackground
        isOpen={isVisible}
        onClose={() => setIsVisible(false)}
        // standardButtonsOnBottom={[
        //   {
        //     text: t("b_cancel"),
        //     type: "normal",
        //     handler: () => setIsVisible(false),
        //   },
        // ]}
      >
        <div
          style={{
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 8,
          }}
        >
          <>
            <div>
              <img src={qrCode} style={{ width: 128, height: 128 }} />
            </div>
            <Button
              onClick={() => {
                const link = document.createElement("a")
                link.href = qrCode
                link.download = `${station.name}_(${device.displayName})_(${device.sn})`
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }}
              text="下载"
            ></Button>
          </>
        </div>
      </Dialog>
    </>
  )
}
