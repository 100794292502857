import React, { useState, useEffect, useRef } from "react"
import { WithTooltip, Tooltip } from "@abb/abb-common-ux-react"
import { getDistributorPage, deleteDistributor } from "common/apis/maindata"
import { useSelector } from "react-redux"
import { CommonIndex } from "components/LoongInCommon"
import { FormEdit } from "./FormEdit"
import moment from "moment"
import { getUsers } from "slices/userSlice"
import { getDictionary } from "slices/dataDictionarySlice"
import { getDataDictionaryDetail } from "common/dataDictionary"
import { t } from "i18next"
import { useHistory } from "react-router-dom"
import { ImportFormEdit } from "./ImportFormEdit"
import { getAllSettings } from "common/endpoints"
import axios from "axios"
const findTreeItem = (treeList, fieldName, value) => {
  if (!treeList) return
  let itm = treeList.find((c) => (c ? c[fieldName] === value : undefined))
  if (itm) {
    return itm
  } else {
    let findItm = undefined
    for (let i = 0; i < treeList.length; i++) {
      const ele = treeList[i]
      if (ele?.children) {
        findItm = findTreeItem(ele.children, fieldName, value)
      }
      if (findItm) {
        return findItm
      }
    }
    return findItm
  }
}

export default function Distributors() {
  const history = useHistory()

  const [deletedItems, setDeletedItems] = useState([])
  const users = useSelector(getUsers)
  const dictionary = useSelector(getDictionary)
  const srvArea = getDataDictionaryDetail(dictionary, "srvArea")
  const distributorlevel = getDataDictionaryDetail(
    dictionary,
    "Distributorlevel"
  )
  const distributorStatus = getDataDictionaryDetail(
    dictionary,
    "DistributorStatus"
  )
  const refIndex = useRef(null)
  const refImport = useRef(null)
  const [customColumnKey, setCustomColumnKey] = useState({})
  //其他行菜单
  const otherMenu = (item, index) => {
    return [
      {
        onClick: () => showEngineer(item),
        title: t("distributorEngineer.menu"),
      },
    ]
  }
  //打开权限树
  const showEngineer = (item) => {
    history.push("/maindata/distributorEngineers/" + item.id)
  }

  //列定义
  const columns = [
    {
      title: "distributor.name",
      key: "name",
      hidden: !customColumnKey["MainData.Distributor.name"],
      //通过渲染的方式将内容渲染出来
      render: (item, index) => {
        const isDeleted = deletedItems.indexOf(item.id) >= 0
        if (isDeleted)
          return (
            <WithTooltip>
              <del>{item.name}</del>
              <Tooltip>
                <div>经销商已被删除</div>
              </Tooltip>
            </WithTooltip>
          )
        else return item.name
      },
    },
    {
      title: "distributor.code",
      key: "code",
      hidden: !customColumnKey["MainData.Distributor.code"],
    },
    {
      title: "distributor.level",
      key: "level",
      hidden: !customColumnKey["MainData.Distributor.level"],
      render: (item, index) => {
        const level = distributorlevel?.details.find(
          (d) => d.code == item.level
        )
        return level?.displayText
      },
    },
    {
      title: "distributor.tag",
      key: "tag",
      hidden: !customColumnKey["MainData.Distributor.tag"],
    },
    {
      title: "distributor.startDate",
      key: "startDate",
      hidden: !customColumnKey["MainData.Distributor.startDate"],
      render: (item) => {
        return moment(item.startDate).format("yyyy-MM-DD")
      },
    },
    {
      title: "distributor.finishedDate",
      key: "finishedDate",
      hidden: !customColumnKey["MainData.Distributor.finishedDate"],
      render: (item) => {
        return moment(item.finishedDate).format("yyyy-MM-DD")
      },
    },
    {
      title: "distributor.manager",
      key: "manager",
      hidden: !customColumnKey["MainData.Distributor.manager"],
      render: (item, index) => {
        const manager = users.find((d) => d.id == item.manager)
        return manager?.name
      },
    },
    {
      title: "distributor.contact",
      key: "contact",
      hidden: !customColumnKey["MainData.Distributor.contact"],
    },
    {
      title: "distributor.phone",
      key: "phone",
      hidden: !customColumnKey["MainData.Distributor.phone"],
    },
    {
      title: "distributor.email",
      key: "email",
      hidden: !customColumnKey["MainData.Distributor.email"],
    },
    {
      title: "distributor.area",
      key: "area",
      hidden: !customColumnKey["MainData.Distributor.area"],
      render: (item, index) => {
        const area = srvArea?.details.find((d) => d.code == item.area)
        return area?.displayText
      },
    },
    {
      title: "distributor.address",
      key: "address",
      hidden: !customColumnKey["MainData.Distributor.address"],
    },
    {
      title: "distributor.status",
      key: "status",
      hidden: !customColumnKey["MainData.Distributor.status"],
      render: (item, index) => {
        const status = distributorStatus?.details.find(
          (d) => d.code == item.status
        )
        return status?.displayText
      },
    },
  ]?.filter((cl) => !cl.hidden)

  const getAllSettingData = async () => {
    const res = await axios.post(getAllSettings())
    const data = res.data
    const item = findTreeItem(
      data,
      "group",
      "Setting.Group.MainData.Distributor"
    )
    let newCustomColumnKey = item.settingItemOutput.reduce((pre, cur) => {
      pre[cur.name] = JSON.parse(cur.value)
      return pre
    }, {})
    setCustomColumnKey(newCustomColumnKey)
  }
  useEffect(() => {
    getAllSettingData()
  }, [])

  //删除时放入删除项
  const onDeleteData = (deleteId) => {
    setDeletedItems([...deletedItems, deleteId])
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getDistributorPage}
        deleteData={deleteDistributor}
        FormEdit={FormEdit}
        otherMenu={otherMenu}
        canAdd
        canEdit
        canDelete
        canInport
        onImport={() => refImport.current.showPanel()}
        onDeleteData={onDeleteData}
        enableSorting={true}
      />
      <ImportFormEdit
        ref={refImport}
        onSubmit={() => {
          refIndex.current.query()
        }}
      />
    </>
  )
}
