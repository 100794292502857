import React, { forwardRef, useRef } from "react"
import { useEffect, useState } from "react"
import Vditor from "vditor"
import "vditor/dist/index.css"
import save from "./save.svg"
export const MarkdownEditor = forwardRef(
  (
    {
      id,
      value,
      onChange,
      readOnly = false,
      placeholder = "请输入内容",
      style = {},
      options,
    },
    ref
  ) => {
    const [vd, setVd] = useState(null)
    const divRef = useRef(null)
    useEffect(() => {
      console.log("value", value)
      if (readOnly) {
        Vditor.preview(divRef.current, value, {})
      } else {
        const vditor = new Vditor(id, {
          mode: "wysiwyg",
          placeholder: placeholder,
          toolbar: [
            "headings",
            "bold",
            "italic",
            "strike",
            "|",
            "list",
            "ordered-list",
            "check",
            "outdent",
            "indent",
            "|",
            "quote",
            "line",
            "insert-before",
            "insert-after",
            "|",
            "table",
            "|",
            "undo",
            "redo",
            "|",
            "fullscreen",
            "|",
            {
              hotkey: "⌘S",
              name: "save",
              tipPosition: "s",
              tip: "保存",
              className: "right",
              icon: `<img style="height: 16px" src='${save}'/>`,
              click() {
                vd && vd.disabled()
                onChange(vditor.getValue())
                vd && vd.enable()
              },
            },
          ],
          after: () => {
            setVd(vditor)
          },
          ...(options || {}),
        })
      }
      return () => {
        vd && vd.destroy()
        setVd(undefined)
      }
    }, [options])
    useEffect(() => {
      if (readOnly) {
        Vditor.preview(divRef.current, value, {})
      } else if (vd) {
        value == undefined || value == null
          ? vd.setValue("")
          : vd.setValue(value)
      }
    }, [value, vd])
    return <div ref={divRef} id={id} className="vditor" style={style} />
  }
)
